import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 5,
    padding: theme.spacing(2),
    width: '100%',
    height: 250,
  },
}));

const ProcessCard = ({ input }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const getLan = () => {
    return i18n.language === 'ar' ? 'right' : 'left';
  };

  return (
    <Card className={classes.card} variant="elevation" elevation={1}>
      <Typography variant="h6" align={getLan()} color="textPrimary">
        {input}
      </Typography>
    </Card>
  );
};
export default ProcessCard;
