import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden, IconButton,
  List,
  Grid,
  makeStyles, Toolbar
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@material-ui/icons/Clear';
import { AuthenticatedTemplate } from '@azure/msal-react';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from 'src/i18n';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import UserProfile from '../UserProfile';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: '100%',
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  appBar: {
    backgroundColor: theme.palette.background.navigation,
  },
  list: {
    marginTop: theme.spacing(8),
  },
  grid: {
    marginBottom: theme.spacing(10)
  },
  menuItem: {
    color: theme.palette.background.navigation,
  }
}));

const NavBar = ({ drawerOpen, closeDrawer }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = [
    /* {
      href: '/patientService',
      title: t('doctors'),
    },
    {
      href: '/tourism',
      title: t('menu.tourism'),
    },
    {
      href: '/aboutUs',
      title: t('menu.aboutUs'),
    }, */
    {
      href: '/contact',
      title: t('menu.contact'),
    },
  ];

  const handleLanguageChange = (lang) => {
    return () => {
      i18n.changeLanguage(lang);
      closeDrawer();
    };
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Divider />
      <Box p={2}>
        <List className={classes.list}>
          {items.map((item) => (
            <div key={item.title}>
              <NavItem
                closeDrawer={closeDrawer}
                href={item.href}
                key={item.title}
                title={item.title}
              />
              <Divider variant="middle" />
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="top"
          classes={{ paper: classes.mobileDrawer }}
          open={drawerOpen}
          variant="temporary"
        >
          <AppBar
            className={classes.appBar}
            elevation={2}
          >
            <Toolbar>
              <RouterLink onClick={() => drawerOpen(false)} to="/">
                <Logo />
              </RouterLink>
              <Box flexGrow={1} />
              <IconButton
                onClick={closeDrawer}
                color="inherit"
              >
                <ClearIcon />
              </IconButton>
              <AuthenticatedTemplate>
                <UserProfile />
              </AuthenticatedTemplate>
            </Toolbar>
          </AppBar>
          {content}
          <Grid
            className={classes.grid}
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid item>
              <MenuItem className={classes.menuItem} component="a" onClick={handleLanguageChange('de')}>Deutsch</MenuItem>
            </Grid>
            <Grid item>
              <MenuItem className={classes.menuItem} component="a" onClick={handleLanguageChange('en')}>English</MenuItem>
            </Grid>
            <Grid item>
              <MenuItem className={classes.menuItem} component="a" onClick={handleLanguageChange('ar')}>العربية</MenuItem>
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
