/* eslint-disable no-await-in-loop */
import { PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from 'src/authConfig';
import config from 'src/config';

const msalInstance = new PublicClientApplication(msalConfig);

// Try to recover timeouts, internal server errors, teapots or 420
const recoverables = [408, 418, 420, 500, 502, 503, 504];
const MAX_RETRY = 3;
const RETRY_TIMEOUT_MULTIPLIER = 2000;

const callUnauthenticated = async (endpoint, options) => {
  let promise = null;
  let retry = true;
  for (let i = 0; i <= MAX_RETRY; i++) {
    // First and second retry no delay.
    await new Promise((resolve) => setTimeout(resolve, RETRY_TIMEOUT_MULTIPLIER * (i - 1)));
    // eslint-disable-next-line no-loop-func
    promise = await fetch(endpoint, options).then((response) => {
      // If we can't recover this or it is a success don't try again and return (error) response.
      if (response.ok || !recoverables.includes(response.status)) { retry = false; }
      return response;
    });
    if (!retry) { break; }
  }
  return promise;
};

const callAuthenticated = async (endpoint, method = 'GET', body, headers = null) => {
  const accounts = msalInstance.getAllAccounts();
  return msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    })
    .then((response) => {
      const bearer = `Bearer ${response.accessToken}`;

      if (headers === null) {
        headers = new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        });
      }
      headers.append('Authorization', bearer);

      const options = {
        headers,
        method,
        body
      };
      return callUnauthenticated(endpoint, options);
    });
};

const apiCall = async ({
  endpoint, body, method = 'GET', headers = null, authenticated = false
}) => {
  const absEndpoint = config.API_URL + `/${endpoint}`.replace(/\/{2,}/g, '/');
  if (headers === null) {
    headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  if (authenticated) {
    return callAuthenticated(absEndpoint, method, body, headers);
  }
  const options = {
    headers,
    method,
    body
  };
  return callUnauthenticated(absEndpoint, options);
};

const apiAddDoctor = async (body) => {
  return apiCall({
    endpoint: '/doctors/', method: 'POST', body, authenticated: true
  });
};

const apiAddDoctorPicture = async (doctorId, fileHandler) => {
  const formData = new FormData();
  formData.append('file', fileHandler);
  const headers = new Headers({
    /* Do NOT set content type!
       https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
    * */
    type: fileHandler.type
  });
  return apiCall({
    endpoint: `/doctors/${doctorId}/profile_picture/`, method: 'PUT', body: formData, headers, authenticated: true
  });
};

const apiTranslatePost = async (docId, postId, body) => {
  return apiCall({
    endpoint: `/doctors/${docId}/posts/${postId}/`, method: 'PUT', body, authenticated: true
  });
};

const apiEditDoctor = async (docId, body) => {
  return apiCall({
    endpoint: `/doctors/${docId}/`, method: 'PUT', body, authenticated: true
  });
};

const apiDeleteDoctor = async (docId) => apiCall({
  endpoint: `/doctors/${docId}/`,
  method: 'DELETE',
  authenticated: true
});

const apiCreatePost = async (docId, body) => apiCall({
  endpoint: `/doctors/${docId}/posts/`,
  method: 'POST',
  body,
  authenticated: true
});

const apiGetDoctors = async () => apiCall({ endpoint: '/doctors/' });
const apiGetDoctor = async (docId) => apiCall({ endpoint: `/doctors/${docId}/` });
const apiGetDoctorPosts = async (docId) => apiCall({ endpoint: `/doctors/${docId}/posts/` });
const apiGetDoctorPost = async (docId, postId) => apiCall({ endpoint: `/doctors/${docId}/posts/${postId}` });

const apiAddContentToPost = async (docId, postId, body) => apiCall({
  endpoint: `/doctors/${docId}/posts/${postId}/`,
  method: 'PUT',
  body,
  authenticated: true
});

const apiSubmitInquiry = async (body) => apiCall({
  endpoint: '/cases/',
  body,
  method: 'POST'
});

const apiGetCases = async () => {
  return apiCall({
    endpoint: '/cases/', authenticated: true
  });
};

const apiGetCase = async (uuid) => {
  return apiCall({
    endpoint: `/cases/${uuid}`, authenticated: true
  });
};

const toJson = (response) => {
  if (!response.ok) {
    throw response;
  }
  return response.json();
};

export {
  toJson,
  apiGetCases,
  apiGetCase,
  apiAddDoctor,
  apiGetDoctor,
  apiGetDoctors,
  apiEditDoctor,
  apiDeleteDoctor,
  apiCreatePost,
  apiAddContentToPost,
  apiTranslatePost,
  apiGetDoctorPosts,
  apiGetDoctorPost,
  apiAddDoctorPicture,
  apiSubmitInquiry
};
