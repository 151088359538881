import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useMsal } from '@azure/msal-react';

import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import { loginRequest } from 'src/authConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography color="textPrimary" variant="h3">
              {t('admin.login.title')}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              {t('admin.login.subtitle')}
            </Typography>
          </Box>
          <Box my={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleLogin}
            >
              {t('admin.login.btn-text')}
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
