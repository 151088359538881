export default {
  fontFamily: [
    'Cairo',
    'Roboto',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: {
    fontWeight: 400,
  },
  h2: {
    fontWeight: 400,
  },
  h3: {
    fontWeight: 400,
  },
  h4: {
    fontWeight: 400,
  },
  h5: {
  },
  h6: {
  },
  overline: {
    fontWeight: 400
  }
};
