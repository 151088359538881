import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 5,
  },
  tabelContainer: {
    borderRadius: 5,
  },
  table: {
    width: '100%'
  }
});

function createData(day, hours) {
  return { day, hours };
}

const PracticeHours = ({ doctor }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  const rows = [
    createData(t('sunday'), '24/7'),
    createData(t('monday'), '24/7'),
    createData(t('tuesday'), '24/7'),
    createData(t('wednesday'), '24/7'),
    createData(t('thursday'), '24/7'),
    createData(t('friday'), '24/7'),
    createData(t('saturday'), '24/7'),
  ];

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {t('practiceHours')}
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('day')}</TableCell>
              <TableCell align="right">{t('hours')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.day}>
                <TableCell component="th" scope="row">
                  {row.day}
                </TableCell>
                <TableCell align="right">{row.hours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
export default PracticeHours;
