import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  Language,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  avatar: {
    height: 180,
    width: 180
  },
  link: {
    color: theme.palette.background.navigation,
    textTransform: 'lowercase',
  },
}));

const Profile = ({ doctor, elevation }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Card variant="elevation" elevation={elevation} className={classes.root}>
        <CardContent>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Avatar
              className={classes.avatar}
              src={doctor?.blob_href}
            />
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              {doctor?.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
            >
              {t(doctor?.city)}
            </Typography>
            <Button color="primary" variant="outlined" className={classes.link} startIcon={<Language />} href={doctor?.link}>
              {doctor?.link}
            </Button>
          </Box>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
};

export default Profile;
