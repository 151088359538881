import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import NavigationButtoms from '../NavigationButtoms';
import DoctorCardMobile from './DoctorCardMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  div: {
    marginTop: theme.spacing(1),
    minHeight: '100vh',
  },
  button: {
    borderRadius: 30,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
    textTransform: 'none'
  },
  item: {
    marginTop: theme.spacing(2),
  }
}));

const DoctorsListMobile = ({ filteredDoctors, setSelectedMedicalField }) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <Grid container direction="column">
        <Grid className={classes.item} item xs={12}>
          <NavigationButtoms />
        </Grid>
        <Grid className={classes.item} item xs={12}>
          <Toolbar setSelectedMedicalField={setSelectedMedicalField} />
        </Grid>
        <Grid className={classes.item} item xs={12}>
          <List className={classes.root}>
            {filteredDoctors.map((doctor) => (
              <div key={doctor.id}>
                <DoctorCardMobile doctor={doctor} />
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};
export default DoctorsListMobile;
