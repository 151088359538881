export default [
  { title: 'All', key: 'all' },
  { title: 'Cardiology', key: 'cardiology' },
  { title: 'Phlebology', key: 'phlebology' },
  { title: 'Dentist', key: 'dentist' },
  { title: 'Dermatology', key: 'dermatology' },
  { title: 'Gynecology', key: 'gynecology' },
  { title: 'ENT-Medicine', key: 'entMedicine' },
  { title: 'Eye specialist', key: 'eyeSpecialist' },
  { title: 'Gastroenterology ', key: 'gastroenterology' },
  { title: 'Internal medicine ', key: 'internalMedicine' },
  { title: 'Neurology', key: 'neurology' },
  { title: 'Neurosurgery ', key: 'neurosurgery' },
  { title: 'Oncology', key: 'oncology' },
  { title: 'Orthopadics', key: 'orthopadics' },
  { title: 'Plastic & aesthetic surgery', key: 'plasticSurgery' },
  { title: 'Pediatricians ', key: 'pediatricians' },
  { title: 'Radiology', key: 'radiology' },
  { title: 'Stem cells', key: 'stemCells' },
  { title: 'Surgery Urology', key: 'surgeryUrology' },
  { title: 'Visiting doctors', key: 'visitingDoctors' },
  { title: 'University Hospitals', key: 'universityHospitals' },
  { title: 'Specialist clinics', key: 'specialistClinics' },
  { title: 'Rehabilitation clinics', key: 'rehabilitationClinics' },
  { title: 'Maximum care hospitals', key: 'maximumCareHospitals' },
].sort((a, b) => (((a.key !== 'all' && b.key !== 'all') && a.key > b.key) || b.key === 'all'));
