import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Check from '@material-ui/icons/Check';
import WorkIcon from '@material-ui/icons/Work';
import DuoIcon from '@material-ui/icons/Duo';
import StepConnector from '@material-ui/core/StepConnector';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import StepContent from '@material-ui/core/StepContent';
import { useTranslation } from 'react-i18next';
import ProcessCard from './ProcessCard';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <ContactPhoneIcon fontSize="small" />,
    2: <ContactSupportIcon fontSize="small" />,
    3: <WorkIcon fontSize="small" />,
    4: <DuoIcon fontSize="small" />,
    5: <DoneAllIcon fontSize="small" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    width: '100%',
    backgroundColor: theme.palette.background.dark,
  },
  button: {
    float: 'right',
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
  },
  buttons: {
    padding: theme.spacing(5),
  },
  title: {
    padding: theme.spacing(3),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Process = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ProcessCard input={t('process.step1Body')} />;
      case 1:
        return <ProcessCard input={t('process.step2Body')} />;
      case 2:
        return <ProcessCard input={t('process.step3Body')} />;
      case 3:
        return <ProcessCard input={t('process.step4Body')} />;
      case 4:
        return <ProcessCard input={t('process.step5Body')} />;
      default:
        return 'This is the bit I really care about!';
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);

  function getSteps() {
    return [t('process.step1Title'), t('process.step2Title'), t('process.step3Title'), t('process.step4Title'), t('process.step5Title')];
  }
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3" align="center" gutterBottom>
        {t('process.header')}
      </Typography>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <Stepper className={classes.root} activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                <StepContent>
                  {getStepContent(index)}
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      {t('back')}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? t('reset') : t('next')}
                    </Button>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            handleReset()
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default Process;
