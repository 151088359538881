import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles, Hidden
} from '@material-ui/core';
import Page from 'src/components/Page';
import Config from 'src/config';
import { useTranslation } from 'react-i18next';
import Toolbar from './Toolbar';
import DoctorCard from './DoctorCard';
import NavBar from '../NavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%',
  },
  toolBar: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  card: {
    minHeight: 70,
    borderRadius: 5,
  },
  title: {
    marginBottom: 10,
    color: theme.palette.background.navigation,
  }
}));

const DoctorsList = ({ filteredDoctors, setSelectedMedicalField }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Page
      className={classes.root}
      title="Partners"
    >
      <div>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={false} sm={false} md={false} lg={1} xl={2} />
          <Grid item xs={false} sm={false} md={false} lg={2} xl={2}>
            <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
          </Grid>
          <Grid item direction="column" container xs={12} sm={12} md={12} lg={8} xl={6}>

            <Grid item className={classes.toolBar}>
              <Toolbar setSelectedMedicalField={setSelectedMedicalField} />
            </Grid>

            <Grid item>
              <Card variant="outlined" className={classes.card}>
                <Box mt={2} p={1}>
                  <Typography className={classes.title} align="center" variant="subtitle2" gutterBottom>
                    {t('clickOnDoctor')}
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                  >
                    {filteredDoctors?.map((doctor) => (
                      <Grid
                        item
                        key={doctor.name}
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <DoctorCard
                          className={classes.productCard}
                          doctor={doctor}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default DoctorsList;
