import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import {
  DriveEta, FlightTakeoff, Translate, Hotel, Description, ContactMail, LocalHospital
} from '@material-ui/icons';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import Flight from './Flight';
import Driver from './Driver';
import Translator from './Translator';
import Accomodation from './Accomodation';
import Doctor from './Doctor';
import Documents from './Documents';
import PersonalInformations from './PersonalInformations';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    minHeight: '100vh',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <FlightTakeoff />,
    2: <DriveEta />,
    3: <Translate />,
    4: <Hotel />,
    5: <LocalHospital />,
    6: <Description />,
    7: <ContactMail />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {icons[String(props.icon)]}
    </div>
  );
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    backgroundColor: '#008dc9',
    color: 'white',
    marginRight: theme.spacing(10),
    float: 'right',
  },
  buttonBack: {
    marginLeft: theme.spacing(10),
    float: 'left',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const RequestStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { t, i18next } = useTranslation();

  const [state, setState] = React.useState({});

  const [flight, setFlight] = React.useState('');
  const [translator, setTranslator] = React.useState('');
  const [driver, setDriver] = React.useState('');
  const [accomodation, setAcoomodation] = React.useState('');
  const [doctor, setDoctor] = React.useState('');
  const [documents, setDocuments] = React.useState();
  const [personalInformation, setPersonalInformation] = React.useState();

  function getSteps() {
    return [t('requestStepper.stepFlight'), t('requestStepper.stepDriver'), t('requestStepper.stepTranslator'), t('requestStepper.stepAccommodation'), t('requestStepper.stepDoctor'), t('requestStepper.stepInformations'), t('requestStepper.stepDocuments')];
  }

  const steps = getSteps();

  const setSelectedTranslator = (input) => {
    setState(state[translator] = input);
    setTranslator(input);
  };
  const setSelectedFlight = (input) => {
    setFlight(input);
  };
  const setSelectedDriver = (input) => {
    setDriver(input);
  };
  const setSelectedAccomodation = (input) => {
    setAcoomodation(input);
  };
  const setSelectedDoctor = (input) => {
    setDoctor(input);
  };
  const setSelectedDocuments = (input) => {
    setDocuments(input);
  };
  const setSelectedPersonalInformation = (input) => {
    setPersonalInformation(input);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  saveState({ lol: 'what' });

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Flight handleNext={handleNext} setFlight={setFlight} />;
      case 1:
        return <Driver handleNext={handleNext} setDriver={setDriver} />;
      case 2:
        return <Translator handleNext={handleNext} setTranslator={setTranslator} />;
      case 3:
        return <Accomodation handleNext={handleNext} setAccomodation={setAcoomodation} />;
      case 4:
        return <Doctor handleNext={handleNext} setDoctor={setDoctor} />;
      case 5:
        return <PersonalInformations />;
      case 6:
        return <Documents handleNext={handleNext} setDocuments={setDocuments} />;
      default:
        return t('requestStepper.unknown');
    }
  }
  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              {t('requestStepper.completed')}
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              {t('requestStepper.reset')}
            </Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>{getStepContent(activeStep)}</div>
            <div>
              <Button startIcon={<ArrowBackIosIcon />} disabled={activeStep === 0} variant="outlined" onClick={handleBack} className={classes.buttonBack}>
                {t('requestStepper.back')}
              </Button>
              <Button
                endIcon={<KeyboardArrowRightIcon />}
                variant="contained"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? t('requestStepper.finish') : t('requestStepper.skip')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RequestStepper;
