import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { apiGetDoctorPost, apiTranslatePost, toJson } from 'src/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(5),
    width: '100%',
  },
  button: {
    color: theme.palette.background.navigation,
    borderColor: theme.palette.background.navigation,
    '&:hover': {
      backgroundColor: theme.palette.background.navigation,
      color: 'white',
    }
  },
  alert: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  search: {
    width: '100%',
  }
}));

const TranslatePostDrawer = ({
  language, docId, postId, onSuccess
}) => {
  const classes = useStyles();
  const [disableEditable, setDisableEditable] = useState(true);
  const [initialTitle, setInitialTitle] = useState('');
  const [initialText, setInitialText] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    apiGetDoctorPost(docId, postId)
      .then(toJson)
      .then((contents) => {
        setDisableEditable(false);
        const content = contents.filter((c) => c.lang === language)[0];
        if (content) {
          setInitialText(content.text);
          setInitialTitle(content.title);
        }
      });
  });

  const createPostWithContent = ((input, setSubmitting) => {
    const createPost = async () => {
      apiTranslatePost(docId, postId, JSON.stringify({
        title: input.title,
        text: input.text,
        lang: language
      }))
        .then(toJson)
        .then((json) => {
          setSubmitting(false);
          enqueueSnackbar('A post has been translated!', { variant: 'success' });
          if (onSuccess) {
            onSuccess(json);
          }
        }).catch(() => {
          setSubmitting(false);
          enqueueSnackbar('Could not translate post', { variant: 'success' });
        });
    };
    createPost();
  });

  return (
    <Grid container direction="row">
      <Paper className={classes.paper}>
        <Formik
          enableReinitialize
          initialValues={{
            title: initialTitle,
            text: initialText,
          }}
          validationSchema={
              Yup.object().shape({
                title: Yup.string().max(255).required('Title is required'),
                text: Yup.string().max(3000).required('Text is required'),
              })
            }
          onSubmit={(values, { setSubmitting }) => {
            createPostWithContent(values, setSubmitting);
            setSubmitting(true);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h5"
                  align="center"
                >
                  Translate Post
                </Typography>
              </Box>
              <Grid item>
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  helperText={touched.title && errors.title}
                  label="title"
                  margin="normal"
                  disabled={disableEditable}
                  name="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  error={Boolean(touched.text && errors.text)}
                  fullWidth
                  multiline
                  rows={2}
                  disabled={disableEditable}
                  helperText={touched.text && errors.text}
                  label="text"
                  margin="normal"
                  name="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.text}
                  variant="outlined"
                />
              </Grid>
              <Box my={2}>
                {isSubmitting && <LinearProgress />}
                <Grid item container justify="center" alignItems="center">
                  <Button
                    className={classes.button}
                    disabled={isSubmitting || disableEditable}
                    size="large"
                    type="submit"
                    variant="outlined"
                  >
                    Save
                  </Button>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </Paper>
    </Grid>
  );
};

export default TranslatePostDrawer;
