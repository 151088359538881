import {
  DriveEta,
  LocalHospital,
  Translate,
  Hotel, Language,
} from '@material-ui/icons';

export default [
  {
    description: 'description1',
    title: 'Doctors',
    icon: LocalHospital
  },
  {
    description: 'description2',
    title: 'Hospitals',
    icon: LocalHospital
  },
  {
    description: 'description3',
    title: 'Translators',
    icon: Translate
  },
  {
    description: 'description4',
    title: 'Drivers',
    icon: DriveEta
  },
  {
    description: 'description5',
    title: 'Accomodations',
    icon: Hotel
  },
  {
    description: 'description6',
    title: 'Support',
    icon: LocalHospital
  },
  {
    description: 'description7',
    title: 'Free',
    icon: LocalHospital
  },
  {
    description: 'description8',
    title: 'Online',
    icon: Language
  },
];
