import React, { useEffect, useState } from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { apiGetDoctorPosts, toJson } from 'src/api';
import Profile from './Profile';
import Post from './Post';
import CreatePost from '../CreatePost';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  productCard: {
    height: '100%',
  },
  card: {
    borderRadius: 20,
  },
  profile: {
    paddingBottom: theme.spacing(2)
  },
  back: {
    position: 'fixed',
    top: 100,
    left: 10,
    opacity: 0.4
  },
  grids: {
    marginBottom: theme.spacing(2),
  }
}));

const ProfileView = ({ doctorProfile }) => {
  const classes = useStyles();
  const [posts = [], setPosts] = useState();

  useEffect(() => {
    if (doctorProfile && doctorProfile.id) {
      apiGetDoctorPosts(doctorProfile.id)
        .then(toJson)
        .then(setPosts)
        .catch(() => setPosts([{
          content: {
            en: {
              title: 'Failed to load posts',
              text: ''
            }
          },
          created_date: '2021-04-09T09:01:23.826297',
          id: 4
        }]));
    }
  }, []);

  const onSuccess = ((post) => {
    const newPosts = [...posts];
    newPosts.push(post);
    setPosts(newPosts);
  });
  return (
    <Page
      className={classes.root}
      title="Profile"
    >
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="stretch"
      >
        <Grid className={classes.grids} item xs={12} sm={12}>
          <Profile doctor={doctorProfile} />
        </Grid>
        <Grid className={classes.grids} item>
          {/* eslint-disable-next-line max-len */}
          <CreatePost docId={doctorProfile?.id} post={posts} onSuccess={onSuccess} />
        </Grid>
        {posts.map((item) => (
          <Grid key={item.id} className={classes.grids} item>
            <Post doctor={doctorProfile} postInput={item} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

export default ProfileView;
