import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import {
  Box,
  Button,
  Paper,
  TextField,
  LinearProgress,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  Autocomplete,
} from 'formik-material-ui-lab';
import { useSnackbar } from 'notistack';
import { apiCreatePost, apiAddContentToPost, toJson } from 'src/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(5),
    width: '100%',
  },
  button: {
    color: theme.palette.background.navigation,
    borderColor: theme.palette.background.navigation,
    '&:hover': {
      backgroundColor: theme.palette.background.navigation,
      color: 'white',
    }
  },
  alert: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  }
}));
const fieldsList = ['ar', 'en', 'de'];

const CreatePost = ({ docId, onSuccess }) => {
  const classes = useStyles();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formikRef = useRef();

  const createPostWithContent = (input, setSubmitting) => {
    apiCreatePost(docId, JSON.stringify({}))
      .then(toJson)
      .then((postBody) => {
        apiAddContentToPost(docId, postBody.id, JSON.stringify({
          title: input.title,
          text: input.text,
          lang: input.autocomplete
        }))
          .then(toJson)
          .then((json) => {
            if (onSuccess) {
              const result = { ...postBody };
              const content = {};
              content[json.lang] = json;
              result.content = content;
              onSuccess(result);
            }
            formikRef.current?.resetForm();
            setIsSuccess(true);
            setSubmitting(false);
          });
      })
      .catch((err) => {
        setSubmitting(false);
        enqueueSnackbar('Could not create post', { variant: 'success' });
        setIsFailed(true);
      });
    enqueueSnackbar('A post has been created!', { variant: 'success' });
  };

  return (
    <Grid container direction="row">
      <Paper className={classes.paper}>
        <Formik
          innerRef={formikRef}
          initialValues={{
            title: '',
            text: '',
            autocomplete: '',
          }}
          validationSchema={
              Yup.object().shape({
                title: Yup.string().max(255).required('Title is required'),
                text: Yup.string().max(3000).required('Text is required'),
                autocomplete: Yup.string().max(50).required('Language code is required'),
              })
            }
          onSubmit={(values, { setSubmitting }) => {
            setIsSuccess(false);
            setIsFailed(false);
            setSubmitting(true);
            createPostWithContent(values, setSubmitting);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h5"
                  align="center"
                >
                  Create New Post
                </Typography>
              </Box>
              <Grid item>
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  fullWidth
                  helperText={touched.title && errors.title}
                  label="title"
                  margin="normal"
                  name="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Field
                  name="autocomplete"
                  component={Autocomplete}
                  options={fieldsList}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={touched.autocomplete && !!errors.autocomplete}
                      helperText={touched.autocomplete && errors.autocomplete}
                      label="Language"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  error={Boolean(touched.text && errors.text)}
                  fullWidth
                  multiline
                  rows={2}
                  helperText={touched.text && errors.text}
                  label="text"
                  margin="normal"
                  name="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.text}
                  variant="outlined"
                />
              </Grid>
              <Box my={2}>
                {isSubmitting && <LinearProgress />}
                {isSuccess && <Alert onClose={() => setIsSuccess(false)} className={classes.alert} severity="success">This is a success alert — check it out!</Alert>}
                {isFailed && <Alert onClose={() => setIsFailed(false)} className={classes.alert} severity="error">This is an error alert — check it out!</Alert>}
                <Grid item container justify="center" alignItems="center">
                  <Button
                    className={classes.button}
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="outlined"
                  >
                    Create post
                  </Button>
                </Grid>
              </Box>

            </form>
          )}
        </Formik>
      </Paper>

    </Grid>

  );
};

export default CreatePost;
