import React, {
  useEffect, useMemo, useState
} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Chip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { apiDeleteDoctor, apiGetDoctors, toJson } from 'src/api';
import EditDoctorDrawer from './EditDoctorDrawer';
import ProfileView from './Profile';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100vh',
    overflow: 'scroll'
  },
  input: {
    display: 'none',
  },
  search: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',

  },
  profile: {
    backgroundColor: theme.palette.background.dark,
  },
  div: {
    overflow: 'scroll',
    height: '100vh'
  },
  avatar: {
    width: 50,
    height: 50,
  },
  card: {
    marginTop: theme.spacing(1),
    marginDown: theme.spacing(1),
    borderRadius: 10,
  },
  media: {
    height: 190,
  },
}));

const EditDoctor = () => {
  const classes = useStyles();
  const [doctors = [], setDoctors] = useState();
  const [editData, setEditData] = React.useState();
  const [filter, setFilter] = useState('');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    apiGetDoctors().then(toJson)
      .then(setDoctors)
      .catch(() => {
        enqueueSnackbar(t('errors.noDoctors'), { variant: 'error' });
      });
  }, []);

  const filteredDoctors = useMemo(() => {
    // eslint-disable-next-line max-len
    return doctors.filter((doctor) => String(doctor.name).toLowerCase().includes(filter.toLowerCase()));
  }, [doctors, filter]);

  const deleteDoctor = async (docId) => {
    apiDeleteDoctor(docId).then((response) => {
      if (!response.ok) {
        enqueueSnackbar('Could not delete doctor', { variant: 'error' });
        throw response;
      }
      return response.json();
    }).then((json) => {
      setDoctors(doctors.filter((doctor) => doctor.id !== json.id));
      enqueueSnackbar('Doctor was successfully deleted.', { variant: 'success' });
    }).catch((err) => console.log(err));
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
      >
        <Grid
          item
          container
          xs={3}
          direction="column"
        >
          <TextField
            className={classes.search}
            margin="dense"
            size="small"
            label="Search for doctor"
            variant="outlined"
            onChange={handleChangeSearch}
          />
          <div className={classes.div}>
            {filteredDoctors.map((doctor) => (
              <Card elevation={5} key={doctor.name + doctor.id} className={classes.card}>
                <CardHeader
                  avatar={(
                    <Avatar className={classes.avatar} alt="Ted talk" src={doctor.blob_href} />)}
                  title={doctor.name}
                  subheader={`${doctor.street} ${doctor.streetNo}, ${doctor.postalCode} ${doctor.city}`}
                />
                <Divider variant="inset" />
                <CardContent>
                  {doctor.medicalFields.map((k) => (
                    <Chip className={classes.chip} key={k} color="default" variant="outlined" label={t(k)} size="small" />
                  ))}
                  <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                    {doctor.link}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {doctor.phone}
                  </Typography>
                </CardContent>
                <Divider variant="middle" />
                <CardActions>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                  />
                  <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditData(doctor);
                      handleClickOpen();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setEditData(doctor);
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    <AccountBoxIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => deleteDoctor(doctor.id)}
                    size="small"
                    aria-label="delete"
                    className={classes.margin}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </CardActions>
              </Card>
            ))}
          </div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Edit doctor</DialogTitle>
            <DialogContent>
              <EditDoctorDrawer initialValues={editData} docId={editData?.id} />
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={8}
          className={classes.profile}
        >
          <Divider orientation="vertical" />
          <div className={classes.div}>
            {!editData ? null : <ProfileView key={editData?.id} doctorProfile={editData} />}
            <Divider orientation="vertical" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default EditDoctor;
