import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  CardActions,
  Avatar,
  Button,
  Divider,
  DialogActions
} from '@material-ui/core';

import TranslateIcon from '@material-ui/icons/Translate';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TranslatePostDrawer from '../TranslatePostDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 5,
  },
  button: {
    marginRight: theme.spacing(1),
  }
}));

const Post = ({ doctor, postInput }) => {
  const classes = useStyles();
  const languages = { ar: 'Arabic', de: 'German', en: 'English' };
  const [lan, setLan] = useState('');
  const [contents, setContents] = useState(postInput.content);
  const availableLanguages = Object.keys(contents);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = (result) => {
    const newState = { ...contents };
    newState[result.lang] = result;
    setContents(newState);
  };

  return (
    <div>
      <Card className={classes.card}>
        <CardHeader
          avatar={(
            <Avatar
              alt="Dr."
              src={doctor?.blob_href}
            />
          )}
          title={doctor?.name}
        />
        <CardContent>
          {[...Object.entries(contents)].map((p) => (
            <div key={p[0].lang + p[1].title}>
              <Divider />
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Language:
                {' '}
                {p[0]}
              </Typography>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Title:
                {' '}
                {p[1].title}
              </Typography>
              <Typography paragraph variant="body1">
                Body:
                {' '}
                {p[1].text}
              </Typography>
            </div>
          ))}
        </CardContent>
        <Divider variant="middle" />
        <CardActions>
          {Object.keys(languages).map((code) => (
            <Button
              onClick={() => {
                setLan(code);
                handleClickOpen();
              }}
              color={availableLanguages.includes(code) ? 'default' : 'primary'}
              size="small"
              key={`translateButton${code}`}
              className={classes.button}
              startIcon={<TranslateIcon />}
              variant="contained"
            >
              {availableLanguages.includes(code) ? `Change ${languages[code]} translation` : `Translate to ${languages[code]}`}
            </Button>
          ))}
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              {/* eslint-disable-next-line max-len */}
              <TranslatePostDrawer docId={doctor.id} language={lan} postId={postInput.id} onSuccess={onSuccess} />
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </CardActions>
      </Card>
    </div>
  );
};
export default Post;
