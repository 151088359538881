import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid, Toolbar, Box, Typography, IconButton, AppBar
} from '@material-ui/core';

import { useNavigate, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { useSnackbar } from 'notistack';
import { apiGetDoctor, apiGetDoctorPosts, toJson } from 'src/api';
import { AuthenticatedTemplate } from '@azure/msal-react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useTranslation } from 'react-i18next';

import UserProfile from 'src/layouts/HomeLayout/UserProfile';
import Profile from './Profile/Profile';
import Contact from './Profile/Contact';
import PracticeHours from './Profile/PracticeHours';
import Post from './Profile/Post';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
  spacing: {
    marginBottom: theme.spacing(1),
  },
  appBar: {
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
  }
}));

const DoctorViewMobile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [doctor, setDoctor] = useState();
  const [posts = [], setPosts] = useState();
  const { t, i18n } = useTranslation();

  const noPostsPost = {
    content: {
      [i18n.language]: {
        title: t('errors.noPosts'),
        text: ''
      }
    },
    created_date: '2021-04-09T09:01:23.826297',
    id: 4
  };

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    apiGetDoctor(id)
      .then(toJson)
      .then(setDoctor)
      .catch(() => enqueueSnackbar(t('errors.noDoctor'), { variant: 'error' }));
  }, []);
  useEffect(() => {
    apiGetDoctorPosts(id)
      .then(toJson)
      .then(setPosts)
      .catch(() => setPosts([noPostsPost]));
  }, []);

  return (
    <Page className={classes.root} title="Profile">
      <Grid
        container
        direction="column"
      >
        <AppBar
          className={classes.appBar}
          elevation={2}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => navigate('/patientService/doctors')}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <Box flexGrow={1} />
            <Typography variant="h6" noWrap>
              {doctor?.name}
            </Typography>
            <Box flexGrow={1} />
            <AuthenticatedTemplate>
              <UserProfile />
            </AuthenticatedTemplate>
          </Toolbar>
        </AppBar>
        <Grid item xs={12} className={classes.spacing}>
          <Profile doctor={doctor} />
        </Grid>
        {posts?.map((item) => (
          <Grid key={item.id} item className={classes.spacing}>
            <Post doctor={doctor} postInput={item} />
          </Grid>
        ))}
        <Grid item xs={12} className={classes.spacing}>
          <Contact doctor={doctor} />
        </Grid>
        <Grid item xs={12} className={classes.spacing}>
          <PracticeHours doctor={doctor} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DoctorViewMobile;
