import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Contact = ({ doctor }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {t('contactProfile')}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {doctor?.name}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {doctor?.street}
          {' '}
          {doctor?.streetNo}
          {' '}
          {doctor?.postalCode}
          {' '}
          {doctor?.city}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {doctor?.phone}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {doctor?.email}
        </Typography>
      </CardContent>
    </Card>
  );
};
export default Contact;
