import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  Language,
} from '@material-ui/icons';
import ProfilePictureUploader from '../ProfilePictureUploader';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  avatar: {
    height: 150,
    width: 150
  },
  link: {
    textTransform: 'lowercase',
  },
}));

const Profile = ({ doctor }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <ProfilePictureUploader doctorId={doctor.id} image={doctor.blob_href} />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h4"
          >
            {doctor?.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
          >
            {t(doctor?.city)}
          </Typography>
          <Button color="primary" className={classes.link} startIcon={<Language />} href={doctor?.link}>
            {doctor?.link}
          </Button>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default Profile;
