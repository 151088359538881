import React, { useState } from 'react';
import {
  makeStyles,
  Button, Avatar, Box,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { apiAddDoctorPicture } from 'src/api';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  avatar: {
    height: 150,
    width: 150,
    cursor: 'pointer',
    opacity: 0.5,
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
  hidden: {
    display: 'none'
  },
  normal: {
    textTransform: 'none',
  }
}));

const ProfilePictureUploader = ({ doctorId, image }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedImage, setSelectedImage] = useState();
  const [fileURL, setFileURL] = useState(image);
  const [disabled, setDisabled] = useState(true);

  const fileChangedHandler = (event) => {
    setSelectedImage(event.target.files[0]);

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = ((input) => {
      setFileURL(input.target.result);
    });
    reader.readAsDataURL(file);
    setDisabled(!disabled);
  };

  const uploadHandler = (event) => {
    apiAddDoctorPicture(doctorId, selectedImage).then(((response) => {
      if (!response.ok) {
        response.text().then((text) => {
          enqueueSnackbar(`Ops lieber Admin: Das hat nicht geklappt: Status Code: ${response.status} ${text}`, { variant: 'error' });
        });
        setFileURL(image);
      }
      setDisabled(true);
      enqueueSnackbar('Picture was suceccfully uploaded', { variant: 'success' });
    }));
  };

  return (
    <div>
      <input
        className={classes.input}
        accept="image/*"
        id="uploader"
        type="file"
        onChange={fileChangedHandler}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="uploader">
        <Avatar
          className={classes.avatar}
          src={fileURL}
        />
      </label>
      <Button className={disabled ? classes.hidden : classes.normal} fullWidth autoFocus size="large" variant="contained" color="primary" onClick={uploadHandler}>Save</Button>
    </div>

  );
};

export default ProfilePictureUploader;
