const Config = {
  DYNAMIC_WEB_CONTENT_URL: 'https://dynamicwebcontent.blob.core.windows.net',
  API_URL: 'https://api.sawa-medi.com',
};

if (process.env.NODE_ENV === 'development' || window.location.href.match('proud-glacier-02094a203-\\d+.westeurope.azurestaticapps.net')) {
  Config.API_URL = 'https://api.sawa-medi.com:3000';
}

if (process.env.REACT_APP_API_URL) {
  // Run with: npm run local
  Config.API_URL = process.env.REACT_APP_API_URL;
}

export default Config;
