import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    maxHeight: 550,
    borderRadius: 20,
  },
  media: {
    maxHeight: 300,
  },
  button: {
    color: '#008dc9',
    borderColor: '#008dc9',
    marginLeft: 'auto',
    float: 'right',
    '&:hover': {
      backgroundColor: '#008dc9',
      color: 'white',
    }
  },
  buttonBack: {
    color: '#008dc9',
    borderColor: '#008dc9',
    marginRight: 'auto',
    float: 'left',
    '&:hover': {
      backgroundColor: '#008dc9',
      color: 'white',
    }
  }
});

// eslint-disable-next-line react/prop-types
const Flight = ({ handleNext, setFlight }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  const handleYes = () => {
    handleNext();
    setFlight('yes');
  };
  const handleNo = () => {
    handleNext();
    setFlight('no');
  };

  return (
    <Grid container justify="center">
      <Card variant="elevation" elevation={10} className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h2" component="h2">
              {t('requestStepperFlight.question')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardMedia
          className={classes.media}
          component="img"
          alt="Contemplative Reptile"
          image="/static/images/flight.png"
        />
        <CardActions>
          <Button onClick={handleNo} className={classes.buttonBack} size="large" variant="outlined">
            {t('requestStepperFlight.answerNo')}
          </Button>
          <Button onClick={handleYes} className={classes.button} size="large" autoFocus variant="outlined">
            {t('requestStepperFlight.answerYes')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default Flight;
