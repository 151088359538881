import React from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import OurStory from './OurStory';
import Media from './index';
import WhatsUpWithTheName from './WhatsUpwithTheName';
import Team from './Team';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  productCard: {
    height: '100%',
  },
  toolBar: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  card: {
    borderRadius: 20,
  },
  mid: {
    paddingBottom: theme.spacing(3),
  },
  top: {
    paddingTop: theme.spacing(2),
  },
  meet: {
    paddingTop: theme.spacing(5),
  },
}));

const AboutUsTest = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const getLan = () => {
    return i18n.language === 'ar' ? 'right' : 'left';
  };

  return (
    <Page
      className={classes.root}
      title="About us"
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.top}
      >
        <Grid item xs={false} sm={false} md={1} lg={1} xl={2} />
        {i18n.language === 'ar' ? (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              <Media />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              <OurStory align={getLan()} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              <OurStory align={getLan()} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
              <Media />
            </Grid>
          </>
        ) }
        <Grid item xs={false} sm={false} md={1} lg={1} xl={2} />

        <Grid item className={classes.mid} xs={4} sm={4} md={12} lg={12} xl={12} />

        <Grid item xs={false} sm={false} md={1} lg={1} xl={2} />
        <Grid item xs={12} sm={12} md={8} lg={8} xl={6}>
          <WhatsUpWithTheName align={getLan()} />
        </Grid>
        <Grid item xs={4} sm={4} md={1} lg={1} xl={2} />
      </Grid>
    </Page>
  );
};

export default AboutUsTest;
