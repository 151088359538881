import React from 'react';
import Page from 'src/components/Page';

import {
  Box,
  Paper,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(5),
    borderRadius: 6,
  },
  button: {
    color: theme.palette.background.navigation,
    borderColor: theme.palette.background.navigation,
    '&:hover': {
      backgroundColor: theme.palette.background.navigation,
      color: 'white',
    }
  },
}));

const Imprint = () => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <Page
      className={classes.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Paper className={classes.paper} variant="elevation" elevation={20}>
            <Typography variant="h2">
              {t('impressum')}
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              {t('imprint')}
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Page>
  );
};

export default Imprint;
