import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import {
  Call,
  LocationOn,
  Language,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex',
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
  iconButton: {
    color: theme.palette.background.navigation,
  },
  media: {
    width: 130,
    height: 130,
  },
}));

const TourismCard = ({
  className, product, image, ...rest
}) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <Card
      variant="outlined"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          mb={3}
        >
          <Avatar
            className={classes.media}
            alt="Product"
            src={`/static/tourism/${product.name}.${image}`}
            variant="circle"
          />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h6"
        >
          {product.name}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {product.description}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={1}>
        <Grid
          className={classes.statsItem}
          item
        >
          <LocationOn
            className={classes.iconButton}
          />
          {product.address}
        </Grid>
      </Box>
      <Divider />
      <Box p={1}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            <IconButton href={`tel:${product.phone}`} aria-label="delete" className={classes.iconButton} size="small">
              <Call />
            </IconButton>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <IconButton href={product.link} aria-label="delete" className={classes.iconButton} size="small">
              <Language />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </Card>
  );
};

export default TourismCard;
