import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 5,
  },
  tabelContainer: {
    borderRadius: 5,
  }
});

const OurStory = ({ align }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <CardContent>
      <Typography align={align} variant="h4" gutterBottom>
        {t('aboutUs.we.title')}
      </Typography>
      <Typography align={align} paragraph variant="body1" gutterBottom>
        {/* eslint-disable-next-line max-len,react/no-unescaped-entities */}
        {t('aboutUs.story.body')}
      </Typography>
    </CardContent>
  );
};
export default OurStory;
