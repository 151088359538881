import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NavigationButtoms from '../NavigationButtoms';
import TourismMobileCard from './TourismMobileCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  div: {
    marginTop: theme.spacing(1),
    minHeight: '100vh',
  },
  button: {
    borderRadius: 30,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
    textTransform: 'none'
  },
  item: {
    marginTop: theme.spacing(2),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const TourismListViewMobile = ({ tourisms, image, header }) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <Grid container direction="column" justify="flex-start">
        <Grid className={classes.item} item xs={12}>
          <NavigationButtoms />
        </Grid>
        <Grid className={classes.item} alignItems="flex-start" item container>
          {tourisms.map((tourism) => (
            <Grid className={classes.grid} key={tourism.name + tourism.address} item xs={6}>
              <TourismMobileCard
                key={tourism.name + tourism.address}
                tourism={tourism}
                image={image}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
export default TourismListViewMobile;
