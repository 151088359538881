import {
  DriveEta,
  LocalHospital,
  Hotel, Apartment, Translate, Person
} from '@material-ui/icons';

export default [
  {
    title: 'Doctors',
    icon: LocalHospital,
    href: '/patientService/doctors'
  },
  {
    title: 'Hospitals',
    icon: Apartment,
    href: '/patientService/hospitals'
  },
  {
    title: 'Accomodations',
    icon: Hotel,
    href: '/tourism/accomodation'

  },
  {
    title: 'Shops',
    icon: DriveEta,
    href: '/tourism/shops'

  },
];
