import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Chip,
} from '@material-ui/core';
import {
  Call,
  LocationOn,
  Language,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toJson, apiGetDoctorPosts } from 'src/api';

function shortenStr(mystr, max) {
  if (mystr && mystr.length > max) {
    return `${mystr.substring(0, max - 1)}...`;
  }
  return mystr;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    color: theme.palette.background.navigation,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  iconButton: {
    cursor: 'pointer',
    color: theme.palette.background.navigation,
  },
  chip: {
    margin: theme.spacing(1),
    color: theme.palette.background.navigation,
    borderColor: theme.palette.background.navigation,
  },
  image: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  }
}));

const DoctorCard = ({
  className, doctor, ...rest
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [posts = [], setPosts] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (doctor && doctor.id) {
      apiGetDoctorPosts(doctor.id)
        .then(toJson)
        .then(setPosts)
        .catch(() => setPosts([]));
    }
  }, []);

  const getTranslation = () => {
    let result;
    if (posts && posts.length > 0) {
      result = posts[0].content[i18n.language];
      if (!result) {
        result = posts[0].content.en;
      }
      if (!result) {
        result = posts[0].content.de;
      }
    }
    return result;
  };

  return (
    <Card
      className={classes.root}
      variant="outlined"
    >
      <CardContent>
        <CardActionArea onClick={() => navigate(`/patientService/doctors/${doctor.id}`)}>
          <Box
            display="flex"
            justifyContent="center"
            mb={3}
          >
            <Avatar
              className={classes.image}
              alt="doctor"
              src={doctor?.blob_href}
              variant="circle"
            />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h6"
          >
            {doctor.name}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            variant="body1"
            gutterBottom
          >
            {shortenStr(getTranslation()?.text, 125)}
          </Typography>
        </CardActionArea>

      </CardContent>
      <Box flexGrow={1} />
      <Divider variant="middle" />

      <Box p={0}>
        <Grid
          className={classes.statsItem}
          item
        >
          {doctor.medicalFields.map((k) => (
            <Chip className={classes.chip} key={k} variant="outlined" label={t(k)} size="small" />
          ))}
        </Grid>
      </Box>
      <Divider variant="middle" />
      <Box p={1}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            <IconButton className={classes.statsIcon} color="primary" href={`tel:${doctor.phone}`} aria-label="delete" size="small">
              <Call />
            </IconButton>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <IconButton className={classes.statsIcon} color="primary" href={`http://maps.google.com/?q=${doctor.street} ${doctor.streetNo}, ${doctor.postalCode} ${doctor.city}`} aria-label="delete" size="small">
              <LocationOn />
              {t(doctor.city)}
            </IconButton>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <IconButton className={classes.statsIcon} color="primary" href={`${doctor.link}`} aria-label="delete" size="small">
              <Language />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </Card>
  );
};

export default DoctorCard;
