import {
  DriveEta,
  LocalHospital,
  Hotel, Apartment, Translate, Person
} from '@material-ui/icons';

export default [
  {
    description: '40+',
    title: 'Doctors',
    icon: LocalHospital,
    href: '/patientService/doctors'
  },
  {
    description: '10+',
    title: 'Hospitals',
    icon: Apartment,
    href: '/patientService/hospitals'
  },
  {
    description: '30+',
    title: 'Translators',
    icon: Translate,
    href: '/aboutUs'
  },
  {
    description: '80+',
    title: 'Customers',
    icon: Person,
    href: '/aboutUs'

  },
  {
    description: '20+',
    title: 'Accomodations',
    icon: Hotel,
    href: '/tourism/accomodation'

  },
  {
    description: '30+',
    title: 'Shops',
    icon: DriveEta,
    href: '/tourism/shops'

  },
];
