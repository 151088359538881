import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Avatar, Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import data from './TeamData';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  div: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 3),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      borderRadius: 5,
      boxShadow: '0px 12px 20px 0px rgba(0,0,0,0.2)',
    },
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    width: '100vw',
    backgroundColor: theme.palette.background.dark,
  },
  avatar: {
    minWidth: 150,
    minHeight: 150,
  }
}));

const Team = () => {
  const classes = useStyles();
  const [teams] = useState(data);
  const { t } = useTranslation();

  return (
    <div className={classes.div}>
      <Container className={classes.cardGrid}>
        {/* End hero unit */}
        <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
          {t('teamMeet')}
        </Typography>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item container xs={4} sm={4} md={1} lg={3} xl={3} />

          <Grid item container spacing={4}>
            {teams.map((team) => (
              <Grid item key={team.name} xs={12} sm={6} md={4}>
                <Card
                  elevation={15}
                  className={classes.card}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mb={3}
                    >
                      <Avatar
                        alt="Product"
                        variant="circle"
                        className={classes.avatar}
                        src={team.image}
                      />
                    </Box>
                    <Typography
                      align="center"
                      color="textPrimary"
                      gutterBottom
                      variant="h5"
                    >
                      {team.name}
                    </Typography>
                    <Typography
                      align="center"
                      color="textPrimary"
                      variant="body1"
                    >
                      {t(`aboutUs.functions.${team.jobTitleKey}`)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Team;
