import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '80vh',
    objectFit: 'contain',
    // transform: 'rotateY(-30deg) rotateX(10deg) translateZ(0px)'
  }
});

const Media = () => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <CardMedia
      className={classes.root}
      component="img"
      alt="Image of smartphone with app"
      height="120"
      image="/static/Home.svg"
      title="Image of smartphone with app"
    />
  );
};
export default Media;
