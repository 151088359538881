import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Footer from 'src/views/home/Footer';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 55,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const HomeLayout = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerState] = useState(false);

  const anchor = React.createRef();
  const location = useLocation();

  useEffect(
    () => {
      anchor.current.scrollIntoView({ behavior: 'smooth' });
    },
    [location]
  );
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => {
        setDrawerState(true);
      }}
      />
      <NavBar
        drawerOpen={drawerOpen}
        closeDrawer={() => setDrawerState(false)}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <div ref={anchor} />
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
