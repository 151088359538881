import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  image: {
    width: '100%',
    height: '100%',
    flexGrow: 1
  },
  attribution: {
    alignSelf: 'flex-end',
    marginRight: '2.5vw',
    fontStyle: 'italic'
  },
  link: {
    color: 'black',
    textDecoration: 'none'
  }
});

const Media = () => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <div className={classes.root}>
      <CardMedia
        className={classes.image}
        component="img"
        alt="Contemplative Reptile"
        height="140"
        image="/static/aboutUs.png"
        title="Contemplative Reptile"
      />
      <Typography variant="caption" className={classes.attribution}>
        <a className={classes.link} href="https://lottiefiles.com/33624-team-of-businessman">Alexander Rozhkov on LottieFiles</a>
      </Typography>
    </div>
  );
};
export default Media;
