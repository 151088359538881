import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import {
  Box,
  Button,
  TextField,
  LinearProgress,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
} from 'formik-material-ui-lab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { apiSubmitInquiry, toJson } from 'src/api';
import { useSnackbar } from 'notistack';
import countries from './Countries';
import { handleFormErrorResponse } from '../admin/EditDoctorDrawer';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.background.navigation,
    borderColor: theme.palette.background.navigation,
    '&:hover': {
      backgroundColor: theme.palette.background.navigation,
      color: 'white',
    }
  }
}));

const ContactUs = ({ hidden, setHidden }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const parseLocaleIntoLanguage = (locale) => {
    if (locale.includes('-')) {
      return locale.split('-')[0];
    }
    return locale;
  };

  const sendInquiry = async (input, { setSubmitting }) => {
    apiSubmitInquiry(JSON.stringify({
      name: input.name,
      phone: input.phone,
      email: input.email,
      country: input.country.code,
      message: input.description,
      language: parseLocaleIntoLanguage(i18n.language),
      consent: true
    })).then(toJson)
      .then((json) => {
        setHidden(!hidden);
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        if (err.status === 409) {
          enqueueSnackbar(t('errors.inquiryExists'), { variant: 'error' });
        } else {
          handleFormErrorResponse(err, t('errors.inquiryFailed'), enqueueSnackbar);
        }
      });
  };

  return (
    <Grid
      xs={10}
      sm={10}
      md={6}
      lg={6}
      xl={6}
      item
      container
      direction="column"
      justify="center"
      alignItems="stretch"
    >
      <Formik
        initialValues={{
          name: '',
          phone: '',
          email: '',
          description: '',
          country: '',
          dsgvoChecked: false
        }}
        validationSchema={
              Yup.object().shape({
                name: Yup.string().max(50).required(t('required.name')),
                phone: Yup.string().max(20).required(t('required.phone')),
                email: Yup.string().max(100).required(t('required.email')),
                description: Yup.string().max(2500).required(t('required.description')),
                country: Yup.string().ensure().required(t('required.country'))
              })
            }
        onSubmit={sendInquiry}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid item>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('name')}
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={Boolean(touched.phone && errors.phone)}
                fullWidth
                helperText={touched.phone && errors.phone}
                label={t('phone')}
                margin="normal"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('email')}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Field
                name="country"
                component={Autocomplete}
                options={countries}
                getOptionLabel={(option) => (option?.label ? option.label : t('country'))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={touched.country && !!errors.country}
                    helperText={touched.country && errors.country}
                    label={t('country')}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                multiline
                rows={8}
                helperText={touched.description && errors.description}
                label={t('message')}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  /* eslint-disable-next-line react/jsx-wrap-multilines */
                  <Checkbox
                    name="dsgvoChecked"
                    required
                    value={values.dsgvoChecked}
                    color="default"
                    onClick={handleChange}
                  />
                }
                label={t('dsgvoCheckbox', 'I accept the privacy policy')}
              />
            </Grid>
            <Box my={2}>
              {isSubmitting && <LinearProgress />}
              <Grid item container justify="center" alignItems="center">
                <Button
                  className={classes.button}
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="outlined"
                >
                  {t('submit')}
                </Button>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default ContactUs;
