import React, { useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  Box, Hidden
} from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import data from './PartnersDashboradData';

const useStyles = makeStyles((theme) => ({
  div: {
    width: '100%',
    backgroundColor: theme.palette.background.dark,
  },
  cardGrid: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
  avatar: {
    color: theme.palette.background.navigation,
    width: 80,
    height: 80,
  },
  icon: {
    width: 60,
    height: 60,
  },
  avatarMobile: {
    color: theme.palette.background.navigation,
    width: 40,
    height: 40,
  },
  iconMobile: {
    width: 40,
    height: 40,
  },
  number: {
    color: 'limegreen',
  },
  actions: {
    padding: 0,
  }
}));

const PartnersDashboard = () => {
  const classes = useStyles();
  const [products] = useState(data);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={classes.div}>
      <Container className={classes.cardGrid}>
        <Grid container justify="space-between" spacing={4}>
          {products.map((product) => (
            <Grid item key={product.title} xs={4} sm={4} md={2} lg={2}>
              {/* eslint-disable-next-line max-len */}
              <CardActionArea className={classes.actions} onClick={() => { navigate(product.href); }}>
                <CardContent>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="h6"
                  >
                    {t(`homeDisplay.${product.title}`)}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <Hidden smDown>
                      <product.icon className={classes.avatar} />
                    </Hidden>
                    <Hidden mdUp>
                      <product.icon className={classes.avatarMobile} />
                    </Hidden>
                  </Box>
                  <Typography
                    className={classes.number}
                    align="center"
                    variant="h4"
                  >
                    {product.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
export default PartnersDashboard;
