import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500]
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  }
}));

const getInitialLetters = (n) => n.split(' ').map((namePart) => namePart[0]);

const UserProfile = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const { instance } = useMsal();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    instance.logout();
  };

  const { accounts } = useMsal();
  const [name, setName] = React.useState(null);

  React.useEffect(() => {
    if (accounts.length > 0) {
      setName(accounts[0].name);
    }
  }, [accounts]);

  return (
    <div>
      <IconButton
        style={{ color: 'white' }}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar className={classes.purple}>
          {name !== null ? getInitialLetters(name) : 'AZ'}
        </Avatar>
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component="a">{name ?? 'Username'}</MenuItem>
        <MenuItem component="a" onClick={() => navigate('/admin/portal')}>
          {t('user.portal')}
        </MenuItem>
        <MenuItem component="a" href="https://tickets.sawa-medi.com">
          {t('user.tickets')}
        </MenuItem>
        <MenuItem component="a" onClick={handleSignOut}>
          {t('user.logout')}
        </MenuItem>
      </Menu>
    </div>
  );
};
export default UserProfile;
