import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Grid,
  Hidden,
  makeStyles, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Config from 'src/config';
import { useTranslation } from 'react-i18next';
import TourismCard from './TourismCard';
import NavBar from '../NavBar';
import NavigationsButtoms from '../NavigationButtoms';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%',
  },
  toolBar: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  card: {
    borderRadius: 5,
  },
  title: {
    color: theme.palette.background.navigation,
    marginBottom: theme.spacing(3),
  }
}));

const TourismListViewDesktop = ({
  tourisms, image, header
}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Page
      className={classes.root}
      title="Tourism"
    >
      <Grid
        container
        direction="row"
      >
        <Grid item xs={false} sm={false} md={false} lg={1} xl={2} />
        <Hidden smUp>
          <NavigationsButtoms />
        </Hidden>
        <Grid item xs={false} sm={false} md={false} lg={2} xl={2}>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        </Grid>
        <Grid item direction="column" container xs={12} sm={12} md={12} lg={8} xl={6}>
          <Grid item>
            <Card variant="outlined" className={classes.card}>
              <Box mt={2} p={1}>
                <Typography className={classes.title} align="center" variant="subtitle2" color="primary" gutterBottom>
                  {t(header)}
                </Typography>
                <Grid
                  container
                  spacing={3}
                >
                  {tourisms.map((restaurant) => (
                    <Grid
                      item
                      key={restaurant.name + restaurant.address}
                      xs={12}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={3}
                    >
                      <TourismCard
                        className={classes.productCard}
                        image={image}
                        product={restaurant}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default TourismListViewDesktop;
