import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import i18nTranslation from 'src/i18n';

const Language = ({ mobile }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang) => {
    return () => {
      i18nTranslation.changeLanguage(lang);
      setAnchorEl(null);
    };
  };

  const availableLanguages = ['de', 'en', 'ar'];

  return (
    <div>
      <Button startIcon={<GTranslateIcon />} style={{ color: 'white' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {mobile ? null : t('language')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {availableLanguages.map((code) => (
          <MenuItem key={code} component="a" selected={i18n.language === code} onClick={handleLanguageChange(code)}>{t(`languages.${code}`)}</MenuItem>

        ))}
      </Menu>
    </div>
  );
};
export default Language;
