import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, Grid, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContactUs from './Contact';
import Feedback from './Feedback';

const useStyles = makeStyles((theme) => ({
  div: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
    width: '100%'
  },
  grids: {
    marginTop: theme.spacing(3),
  }
}));

const Contact = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);
  return (
    <>
      <div className={classes.div} hidden={!hidden}>
        <Container maxWidth="lg">
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid className={classes.grids} item>
              <Typography align="center" variant="h2" gutterBottom>
                {t('inquiry')}
              </Typography>
            </Grid>
            <ContactUs hidden={hidden} setHidden={setHidden} />
          </Grid>
        </Container>
      </div>
      <Feedback hidden={hidden} />
    </>
  );
};
export default Contact;
