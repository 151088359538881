import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import { makeStyles } from '@material-ui/core/styles';
import LoginView from '../auth/LoginView';
import AdminLayout from './AdminLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark
  },
  appBar: {
    backgroundColor: theme.palette.background.navigation
  }
}));

const ProtectedAdminLayout = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UnauthenticatedTemplate>
        <LoginView />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <AdminLayout />
      </AuthenticatedTemplate>
    </div>
  );
};

export default ProtectedAdminLayout;
