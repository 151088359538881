import React from 'react';
import {
  makeStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    color: theme.palette.background.navigation,
  },
  card: {
    borderRadius: 0,
  },
  indicator: {
    backgroundColor: theme.palette.background.navigation,
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

// eslint-disable-next-line react/prop-types
const Toolbar = ({ setSelectedMedicalField }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();
  const [value, setValue] = React.useState(0);

  const fieldsList = [
    { title: t('allHospital'), key: 'all' },
    { title: t('munich'), key: 'munich' },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedMedicalField(fieldsList[newValue].key);
  };

  return (
    <div>
      <Paper className={classes.card} variant="outlined">
        <Tabs
          className={classes.root}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          classes={{
            indicator: classes.indicator
          }}
          aria-label="scrollable auto tabs filering maps visablity"
        >
          {fieldsList.map((field, index) => (
            <Tab key={field.key} label={field.title} {...a11yProps(index)} />
          ))}
        </Tabs>

      </Paper>
    </div>
  );
};

export default Toolbar;
