import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import AddNewDoctor from './AddNewDoctor';
import Doctors from './Doctors';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.dark,
  },
  appBar: {
    backgroundColor: theme.palette.background.navigation
  }
}));

const AdminLayout = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <AppBar className={classes.appBar} position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Add New Doctor" value="1" />
            <Tab label="Doctors" value="2" />
            <Tab label="Coming Soon..." value="3" />
          </TabList>
        </AppBar>
        <TabPanel value="1"><AddNewDoctor /></TabPanel>
        <TabPanel value="2"><Doctors /></TabPanel>
        <TabPanel value="3">Coming Soon...</TabPanel>
      </TabContext>
    </div>
  );
};
export default AdminLayout;
