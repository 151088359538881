import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  IconButton,
  Avatar,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderRadius: 5,
  },
});

const OurPractice = ({ doctor, post }) => {
  const classes = useStyles();
  const [loading] = React.useState(false);
  const { t, i18next } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          loading ? (
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          ) : (
            <Avatar
              alt="Ted talk"
              src={doctor?.blob_href}
            />
          )
        }
        action={
          loading ? null : (
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={
          loading ? (
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
          ) : (
            doctor?.name
          )
        }
      />
      <CardContent>
        {loading ? (
          <>
            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </>
        ) : (
          <>
            <Typography color="textSecondary" gutterBottom>
              {post?.header}
            </Typography>
            <Typography paragraph>
              {post?.body}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};
export default OurPractice;
