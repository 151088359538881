import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core';

import Logo from 'src/components/Logo';
import theme from 'src/theme';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.background.navigation,
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    display: 'grid',
    placeItems: 'center',
    flexGrow: 1
  }
});

const LoadingScreen = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress />
      <div className={classes.logo}>
        <Logo />
      </div>
    </div>
  );
};

export default LoadingScreen;
