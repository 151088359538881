import React from 'react';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    maxHeight: '40px',
    paddingTop: '4px',
    width: 'auto',
    height: 'auto',
  }
}));

const Logo = (props) => {
  const classes = useStyles();
  return (
    <img
      alt="Logo"
      className={classes.logo}
      src="/static/PNG-02.png"
      {...props}
    />
  );
};

export default Logo;
