import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, Grid, Hidden, Button, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Features from './Features';
import PartnersDashboard from './PartnersDashboard';
import Process from './Process';
import Media from './Media';

const useStyles = makeStyles((theme) => ({
  div: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    width: '100%'
  },
  Header: {
    marginTop: theme.spacing(5),
  },
  Header2: {
    marginTop: theme.spacing(1),
  },
  Header3: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(1),

    backgroundColor: theme.palette.background.navigation,
    color: 'white',
    boxShadow: '0px 12px 20px 0px rgba(0,0,0,0.2)',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.background.navigation,
    }
  }
}));

const Motto = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Hidden smDown>
        <Typography className={classes.Header} align="center" gutterBottom variant="h3">
          {t('motto.part1')}
        </Typography>
        <Typography className={classes.Header2} align="center" variant="h3">
          {t('motto.part2')}
        </Typography>
        <Typography className={classes.Header3} align="center" variant="h3">
          {t('motto.part3')}
        </Typography>
      </Hidden>
      <Hidden mdUp>
        <Typography align="center" gutterBottom variant="h4">
          {t('motto.part1')}
        </Typography>
        <Typography align="center" variant="h4">
          {t('motto.part2')}
        </Typography>
        <Typography align="center" variant="h4">
          {t('motto.part3')}
        </Typography>
      </Hidden>

      {/* <Grid item container alignContent="center" justify="center">
        <Grid item xs={6}>
          <Button onClick={() => navigate('/contact')}
          className={classes.button} variant="contained" fullWidth>
            {t('sendApplication')}
          </Button>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

const HomePage = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <div className={classes.div}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          className={classes.grid}
        >

          <Motto />
          <Grid item xs={6}>
            <Media />
          </Grid>
        </Grid>
      </Container>
      {/* <Grid item container>
        <PartnersDashboard />
      </Grid> */}
      {/* <Grid item container>
        <Features />
      </Grid> */}
      {/* <Grid item container>
        <Process />
      </Grid> */}
    </div>
  );
};
export default HomePage;
