import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toJson, apiGetDoctorPosts } from 'src/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  div: {
    marginTop: theme.spacing(0),
    minHeight: '100vh',
  },
  button: {
    borderRadius: 30,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
    textTransform: 'none'
  },
  chip: {
    marginTop: theme.spacing(1)
  },
}));

function shortenStr(mystr, max) {
  if (mystr && mystr.length > max) {
    return `${mystr.substring(0, max - 1)}...`;
  }
  return mystr;
}

const DoctorCardMobile = ({ doctor }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [posts = [], setPosts] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (doctor && doctor.id) {
      apiGetDoctorPosts(doctor.id)
        .then(toJson)
        .then(setPosts)
        .catch(() => setPosts([]));
    }
  }, []);

  const getTranslation = () => {
    let result;
    if (posts && posts.length > 0) {
      result = posts[0].content[i18n.language];
      if (!result) {
        result = posts[0].content.en;
      }
      if (!result) {
        result = posts[0].content.de;
      }
    }
    return result;
  };

  return (
    <ListItem key={doctor.id} onClick={() => navigate(`/patientService/doctors/m/${doctor?.id}`)} className={classes.item} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt="Doctor" src={doctor?.blob_href} />
      </ListItemAvatar>
      <ListItemText
        primary={doctor?.name}
        secondary={(
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {shortenStr(getTranslation()?.text, 90)}
            </Typography>
            <Grid item xs={10}>
              {doctor.medicalFields.map((k) => (
                <Chip className={classes.button} key={k} label={t(k)} size="small" />
              ))}
              <Chip className={classes.chip} variant="outlined" size="small" label="Munich" />
            </Grid>
          </>
        )}
      />
    </ListItem>
  );
};
export default DoctorCardMobile;
