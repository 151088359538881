import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Typography from '@material-ui/core/Typography';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {
  Storefront,
  Restaurant,
  House,
} from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    color: 'black',
  },
  nav: {
    color: 'black',
  }
}));

const NavigationsButtoms = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction onClick={() => navigate('/tourism/shops')} label={<Typography className={classes.nav}>{t('homeDisplay.Shops')}</Typography>} color="white" icon={<Storefront className={classes.nav} />} />
      <BottomNavigationAction onClick={() => navigate('/tourism/restaurants')} label={<Typography className={classes.nav}>{t('restaurants')}</Typography>} icon={<Restaurant className={classes.nav} />} />
      <BottomNavigationAction onClick={() => navigate('/tourism/accomodation')} label={<Typography className={classes.nav}>{t('homeDisplay.Accomodations')}</Typography>} icon={<House className={classes.nav} />} />
    </BottomNavigation>
  );
};
export default NavigationsButtoms;
