import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  Avatar, Box, Button, Divider
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import data from './FeaturesData';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  div: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 3),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: theme.spacing(8),
  },
  card: {
    borderRadius: 5,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  cardVIP: {
    border: '3px solid #008dc9',
    backgroundColor: theme.palette.background.navigation,
    borderRadius: 5,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    width: '100vw',
    backgroundColor: theme.palette.background.dark,
  },
  avatar: {
    backgroundColor: 'green',
    borderRadius: 5,
  },
  avatarVIP: {
    backgroundColor: theme.palette.background.navigation,
    borderRadius: 5,
    width: 100,
  },
  tyVIP: {
    color: 'white',
  },
  button: {
    maxWidth: 300,
    marginTop: theme.spacing(1),
    backgroundColor: 'white',
    color: theme.palette.background.navigation,
    boxShadow: '0px 12px 20px 0px rgba(0,0,0,0.2)',
    '&:hover': {
      backgroundColor: theme.palette.background.navigation,
      color: 'white',
    }
  }
}));

const Features = () => {
  const classes = useStyles();
  const [products] = useState(data);
  const { t, i18next } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.div}>
      <Container className={classes.cardGrid}>
        {/* End hero unit */}
        <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
          {t('features.header')}
        </Typography>
        <Grid container alignItems="stretch" spacing={4}>
          {products.map((product, index) => (
            <Grid
              item
              key={product.title}
              xs={12}
              sm={index === 0 ? 12 : 6}
              md={index === 0 ? 12 : 4}
              lg={index === 0 ? 12 : 4}
              xl={index === 0 ? 12 : 4}
            >
              <Card
                elevation={15}
                className={index === 0 ? classes.cardVIP : classes.card}
              >
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    mb={2}
                  >
                    <Avatar
                      alt="Product"
                      variant="square"
                      className={index === 0 ? classes.avatarVIP : classes.avatar}
                    >
                      <product.icon />
                    </Avatar>
                  </Box>
                  <Typography
                    align="center"
                    color="textPrimary"
                    variant="h6"
                    className={index === 0 ? classes.tyVIP : null}
                  >
                    {t(`features.${product.description}`)}
                  </Typography>
                  <Grid item container justify="center" alignItems="center">
                    {index === 0 ? (
                      <Button onClick={() => navigate('/contact')} className={classes.button} variant="contained" fullWidth>
                        {t('sendApplication')}
                      </Button>
                    ) : null}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
export default Features;
