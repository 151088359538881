import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import BackupIcon from '@material-ui/icons/Backup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    maxHeight: 550,
    borderRadius: 20,
  },
  media: {
    maxHeight: 300,
  },
  button: {
    borderRadius: 20,
    height: 300,
  },
  buttonBack: {
    marginRight: 'auto',
    float: 'left',
  }
});

// eslint-disable-next-line react/prop-types
const Documents = ({ handleNext, setDocuments }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  const handleYes = () => {
    handleNext();
    setDocuments('yes');
  };

  return (
    <Grid container justify="center">
      <Card variant="elevation" elevation={10} className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h2" component="h2">
              {t('requestStepperDocuments.optional')}
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              {t('requestStepperDocuments.title1')}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button inputMod startIcon={<BackupIcon />} fullWidth className={classes.button} size="large" autoFocus variant="outlined" color="primary">
            {t('requestStepperDocuments.upload')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default Documents;
