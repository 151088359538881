import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  rtl: {
    textAlign: 'right',
  },
  ltr: {},
}));

const Post = ({ doctor, postInput }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const getTranslation = () => {
    let result = postInput.content[i18n.language];
    if (!result) {
      result = postInput.content.en;
    }
    if (!result) {
      result = postInput.content.de;
    }
    return result;
  };

  if (!getTranslation()) return null;
  return (
    <div>
      <Card className={classes.card}>
        <CardHeader
          avatar={(
            <Avatar
              alt="Dr."
              src={doctor?.blob_href}
            />
          )}
          title={doctor?.name}
        />
        <Divider variant="inset" />
        <CardContent>
          <Typography className={(i18n.language === 'ar') ? classes.rtl : classes.ltr} variant="h6" color="textPrimary" gutterBottom>
            {getTranslation().title}
          </Typography>
          <Typography className={(i18n.language === 'ar') ? classes.rtl : classes.ltr} paragraph variant="body1">
            {getTranslation().text}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
export default Post;
