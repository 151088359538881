import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonIcon from '@material-ui/icons/Person';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    color: 'black',
  },
  nav: {
    color: 'black',
  },
  item: {
    borderRight: '1px solid lightGray'
  }
}));

const NavigationsButtoms = ({ path }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(path);
  const { t } = useTranslation();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction className={classes.item} onClick={() => navigate('/patientService/doctors')} label={<Typography className={classes.nav}>{t('doctors')}</Typography>} color="white" icon={<PersonIcon className={classes.nav} />} />
      <BottomNavigationAction onClick={() => navigate('/patientService/hospitals')} label={<Typography className={classes.nav}>{t('hospitals')}</Typography>} icon={<ApartmentIcon className={classes.nav} />} />
    </BottomNavigation>
  );
};
export default NavigationsButtoms;
