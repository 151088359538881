import React from 'react';
import { Navigate } from 'react-router-dom';
import NotFoundView from 'src/views/errors/NotFoundView';
import TourismListView from 'src/views/Tourism/TourismListView/Index';
import Doctors from 'src/views/PatientService/doctors';
import DoctorView from 'src/views/PatientService/doctors/DoctorView';
import DoctorViewMobile from 'src/views/PatientService/doctors/DoctorViewMobile';
import HomePage from 'src/views/home/HomePage';
import RequestStepper from 'src/views/RequestSend/RequestStepper';
import AboutUsTest from 'src/views/AboutUs/indexTest';
import Imprint from 'src/views/imprint/Imprint';
import DataProtection from 'src/views/dataprotection/DataProtection';
import Contact from 'src/views/contactUs/Index';
import HospitalsListView from 'src/views/PatientService/hospitals/Index';
import ProtectedAdminLayout from 'src/views/admin/ProtectedAdminLayout';
import HomeLayout from 'src/layouts/HomeLayout';

const routes = [
  {
    path: '/tourism',
    element: <HomeLayout />,
    children: [
      // { path: 'shops', element: <TourismListView key="1"
      // endpoint="shopping" image="jpeg" header="clickOnShops" /> },
      // { path: 'restaurants', element:
      // <TourismListView key="3" endpoint="restaurants" image="png"
      // header="clickOnRestaurants" /> },
      // { path: 'accomodation', element: <TourismListView key="4"
      // endpoint="hospitality" image="jpeg" header="clickOnAccomodation" /> },
      // { path: '/', element: <Navigate to="/tourism/restaurants" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/patientService',
    element: <HomeLayout />,
    children: [
      // { path: 'doctors', element: <Doctors /> },
      // { path: 'doctors/:id', element: <DoctorView /> },
      // { path: 'doctors/m/:id', element: <DoctorViewMobile /> },
      // { path: 'hospitals', element: <HospitalsListView /> },
      // { path: '/', element: <Navigate to="/patientService/doctors" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <HomeLayout />,
    children: [
      // { path: 'aboutUs', element: <AboutUsTest /> },
      { path: 'request', element: <RequestStepper /> },
      { path: 'privacy', element: <DataProtection /> },
      { path: 'imprint', element: <Imprint /> },
      { path: 'admin/portal', element: <ProtectedAdminLayout /> },
      { path: 'contact', element: <Contact /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomePage /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
];

export default routes;
