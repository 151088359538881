import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  makeStyles,
  Typography,
  IconButton, Button
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate } from '@azure/msal-react';
import UserProfile from './UserProfile';
import Language from './Language';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
  },
  avatar: {
    width: 60,
    height: 60
  },
  button: {
    color: 'white',
    marginLeft: theme.spacing(2)
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={2}
      {...rest}
    >
      <Toolbar>
        <Hidden mdDown>
          <Box flexGrow={1} />
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box flexGrow={4} />
          {/* <RouterLink className={classes.button} to="/patientService">
            <Typography variant="h6">
              {t('menu.patientService')}
            </Typography>
          </RouterLink>
          <Box flexGrow={1} /> */}
          {/*

          <RouterLink className={classes.button} to="/tourism">
            <Typography variant="h6">
              {t('menu.tourism')}
            </Typography>
          </RouterLink>
          <Box flexGrow={1} />

          <RouterLink className={classes.button} to="/aboutUs">
            <Typography variant="h6">
              {t('menu.aboutUs')}
            </Typography>
          </RouterLink>
          <Box flexGrow={1} />
*/}

          <RouterLink className={classes.button} to="/contact">
            <Button variant="outlined" size="small" style={{ color: 'black', outlineColor: 'white', backgroundColor: 'white' }}>
              {t('menu.contact')}
            </Button>
          </RouterLink>
          <Box flexGrow={1} />
          <Language mobile={false} />
          <AuthenticatedTemplate>
            <UserProfile />
          </AuthenticatedTemplate>
        </Hidden>
        <Hidden lgUp>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box flexGrow={1} />
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
          <AuthenticatedTemplate>
            <UserProfile />
          </AuthenticatedTemplate>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
