import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tabelContainer: {
  },
  table: {
    width: '100%'
  }
});

function createData(day, hours) {
  return { day, hours };
}

const PracticeHours = ({ doctor }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  const rows = [
    createData(t('sunday'), doctor?.openingHours['0']),
    createData(t('monday'), doctor?.openingHours['1']),
    createData(t('tuesday'), doctor?.openingHours['2']),
    createData(t('wednesday'), doctor?.openingHours['3']),
    createData(t('thursday'), doctor?.openingHours['4']),
    createData(t('friday'), doctor?.openingHours['5']),
    createData(t('saturday'), doctor?.openingHours['6']),
  ];

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {t('practiceHours')}
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('day')}</TableCell>
              <TableCell align="right">{t('hours')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.day}>
                <TableCell component="th" scope="row">
                  {row.day}
                </TableCell>
                <TableCell align="right">{row.hours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
export default PracticeHours;
