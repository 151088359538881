import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles, Hidden
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import NavBar from '../NavBar';
import Toolbar from './Toolbar';
import HospitalCard from './HospitalCard';
import ProfileView from './Profile';
import NavigationsButtoms from '../NavigationButtoms';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%',
  },
  toolBar: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  card: {
    minHeight: 70,
    borderRadius: 15,
  },
  title: {
    marginBottom: 10,
    color: theme.palette.background.navigation,
  }
}));

const doctors = [
  {
    name: 'Universitätsklinik rechts der Isar',
    street: 'Ismaninger Straße',
    streetNo: '22',
    countryCode: 'de',
    postalCode: 'D - 81675',
    phone: '089 / 4140 - 0',
    email: 'vorstand@mri.tum.de',
    description: 'Das Klinikum rechts der Isar setzt sich mit einem hochkarätigen Team aus Ärzten, Pflegekräften und Wissenschaftlern für die Gesundheit seiner Patienten ein. Mit rund 5.000 Mitarbeitern widmet sich',
    link: 'https://www.mri.tum.de/',
    city: 'Munich',
    medicalFields: [
      ''
    ],
    closed: false,
    openingHours: {
      additionalProp0: '24/7',
      additionalProp1: '24/7',
      additionalProp2: '24/7',
      additionalProp3: '24/7',
      additionalProp4: '24/7',
      additionalProp5: '24/7',
      additionalProp6: '24/7'
    },
    id: 68768,
    blob_href: '/static/hospital/e.jpeg'
  }
];

const HospitalsListView = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [hiddenProfile, setHiddenProfile] = useState(false);
  const [doctorProfile, setDoctorProfile] = useState();
  const { t } = useTranslation();
  const [selectedMedicalField, setSelectedMedicalField] = React.useState('all');

  const filteredDoctors = useMemo(() => {
    if (selectedMedicalField === 'all') {
      return doctors;
    }
    return doctors.filter((doctor) => doctor.city.toLowerCase() === selectedMedicalField);
  }, [doctors, selectedMedicalField]);

  return (
    <Page
      className={classes.root}
      title="Partners"
    >
      <div hidden={hiddenProfile}>
        <Grid
          container
          direction="row"
        >
          <Grid item xs={false} sm={false} md={false} lg={1} xl={2} />
          <Hidden smUp>
            <NavigationsButtoms path={1} />
          </Hidden>
          <Grid item xs={false} sm={false} md={false} lg={2} xl={2}>
            <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
          </Grid>
          <Grid item direction="column" container xs={12} sm={12} md={12} lg={8} xl={6}>

            <Grid item className={classes.toolBar}>
              <Toolbar setSelectedMedicalField={setSelectedMedicalField} />
            </Grid>

            <Grid item>
              <Card variant="outlined" className={classes.card}>
                <Box mt={2} p={1}>
                  <Typography className={classes.title} align="center" variant="subtitle2" color="primary" gutterBottom>
                    {t('clickOnHospital')}
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                  >
                    {filteredDoctors.map((doctor) => (
                      <Grid
                        item
                        key={doctor.name}
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={3}
                      >
                        <HospitalCard
                          className={classes.productCard}
                          doctor={doctor}
                          setDoctorProfile={setDoctorProfile}
                          setHiddenProfile={setHiddenProfile}
                          hiddenProfile={hiddenProfile}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Card>
            </Grid>

          </Grid>
        </Grid>
      </div>
      <div hidden={!hiddenProfile}>
        {/* eslint-disable-next-line max-len */}
        <ProfileView doctorProfile={doctorProfile} setHiddenProfile={setHiddenProfile} hiddenProfile={hiddenProfile} />
      </div>
    </Page>
  );
};

export default HospitalsListView;
