import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InstagramIcon from '@material-ui/icons/Instagram';
import {
  Button, colors, Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Email, WhatsApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.navigation,
    color: colors.common.white
  },
  nolink: {
    color: colors.common.white
  },
  button: {
    color: 'white',
    textTransform: 'none'
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item container direction="column" xs={6} justify="center" alignItems="center">
          <Grid item>
            <Button className={classes.button} size="small" startIcon={<Email className={classes.icons} fontSize="large" />} href="mailto:info@fyndmed.com" component="a">
              info@fyndmed.com
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              className={classes.button}
              size="small"
              startIcon={(<WhatsApp className={classes.icons} fontSize="large" />)}
              href="https://api.whatsapp.com/send?phone=+4915219408320"
              component="a"
            >
              +4915219408320
            </Button>
          </Grid> */}
        </Grid>
        <Grid item container direction="column" justify="center" alignItems="center" xs={6}>
          <Grid item>
            <a href="/privacy" className={classes.nolink}>
              <Typography variant="caption" align="center">{t('dataProtectionTitle')}</Typography>
            </a>
          </Grid>
          <Grid item>
            <a href="/imprint" className={classes.nolink}>
              <Typography variant="caption" align="center">{t('imprintTitle')}</Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};
export default Footer;
