import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import {
  Box,
  Button,
  TextField,
  LinearProgress,
  Grid,
  makeStyles
} from '@material-ui/core';
import { Autocomplete } from 'formik-material-ui-lab';
import { useTranslation } from 'react-i18next';
import { apiEditDoctor, toJson } from 'src/api';
import medicalFields from 'src/MedicalFields';
import { useSnackbar } from 'notistack';
import Profile from './Profile/Profile';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(5),
    borderRadius: 6
  },
  button: {
    color: theme.palette.background.navigation,
    borderColor: theme.palette.background.navigation,
    '&:hover': {
      backgroundColor: theme.palette.background.navigation,
      color: 'white'
    }
  },
}));

const handleFormErrorResponse = async (errorResponse, errorTitle, enqueueSnackbar) => {
  let errorText = `${errorResponse.status}: ${errorResponse.statusText}`;
  if (errorResponse.status === 422) {
    const json = await errorResponse.json();
    const [errorDetail] = json.detail;
    const [_, field] = errorDetail.loc;
    errorText = `${field}: ${errorDetail.msg}`;
  }
  enqueueSnackbar(`${errorTitle}: ${errorText}`, { variant: 'error' });
};

const EditDoctorDrawer = ({ initialValues, docId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const postDoctor = async (input, setSubmitting) => {
    apiEditDoctor(
      docId,
      JSON.stringify({
        name: input.name,
        street: input.street,
        streetNo: input.streetNo,
        countryCode: input.country,
        postalCode: input.postalCode,
        phone: input.phone,
        email: input.email,
        description: input.description,
        link: input.link,
        city: input.city,
        closed: false,
        openingHours: {
          0: input.openingHours0,
          1: input.openingHours1,
          2: input.openingHours2,
          3: input.openingHours3,
          4: input.openingHours4,
          5: input.openingHours5,
          6: input.openingHours6
        },
        medicalFields: input.autocomplete
      })
    )
      .then(toJson)
      .then(() => {
        setSubmitting(false);
        enqueueSnackbar('Doctor edited', { variant: 'success' });
      })
      .catch((response) => {
        handleFormErrorResponse(response, 'Failed to save doctor', enqueueSnackbar);
        setSubmitting(false);
      });
  };

  return (
    <Grid container justify="center" direction="row" alignItems="center">
      <Profile doctor={initialValues} />
      <Formik
        initialValues={{
          name: initialValues.name,
          postalCode: initialValues.postalCode,
          street: initialValues.street,
          streetNo: initialValues.streetNo,
          country: initialValues.countryCode,
          phone: initialValues.phone,
          email: initialValues.email,
          description: initialValues.description,
          link: initialValues.link,
          city: initialValues.city,
          openingHours0: initialValues.openingHours['0'],
          openingHours1: initialValues.openingHours['1'],
          openingHours2: initialValues.openingHours['2'],
          openingHours3: initialValues.openingHours['3'],
          openingHours4: initialValues.openingHours['4'],
          openingHours5: initialValues.openingHours['5'],
          openingHours6: initialValues.openingHours['6'],
          autocomplete: initialValues.medicalFields.map((key) => {
            return { key, title: t(key, { lng: 'en' }) };
          })
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(255)
            .required('Name is required'),
          postalCode: Yup.string()
            .max(255)
            .required('Postal code is required'),
          street: Yup.string()
            .max(255)
            .required('Street is required'),
          country: Yup.string()
            .max(255)
            .required('Country is required'),
          streetNo: Yup.string()
            .max(255)
            .required('Street number is required'),
          phone: Yup.string()
            .max(255)
            .required('Phone is required'),
          email: Yup.string()
            .max(255)
            .required('Email is required'),
          description: Yup.string()
            .max(1000)
            .required('Description is required'),
          link: Yup.string()
            .max(255)
            .required('Link is required'),
          city: Yup.string()
            .max(255)
            .required('City is required'),
          openingHours0: Yup.string()
            .max(255)
            .required('day0 is required'),
          openingHours1: Yup.string()
            .max(255)
            .required('day1 is required'),
          openingHours2: Yup.string()
            .max(255)
            .required('day2 is required'),
          openingHours3: Yup.string()
            .max(255)
            .required('day3 is required'),
          openingHours4: Yup.string()
            .max(255)
            .required('day4 is required'),
          openingHours5: Yup.string()
            .max(255)
            .required('day5 is required'),
          openingHours6: Yup.string()
            .max(255)
            .required('day6 is required'),
          autocomplete: Yup.array().required()
        })}
        onSubmit={(values, { setSubmitting }) => {
          // make sure medical fields are pushed to the api in correct format
          const dataToSubmit = {
            ...values
          };
          dataToSubmit.autocomplete = dataToSubmit.autocomplete.map((valObj) => valObj.key);
          postDoctor(dataToSubmit, setSubmitting);
          setSubmitting(true);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              item
              container
              justify="flex-start"
              alignItems="flex-start"
              direction="row"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(touched.street && errors.street)}
                  fullWidth
                  helperText={touched.street && errors.street}
                  label="street"
                  margin="normal"
                  name="street"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.street}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(touched.streetNo && errors.streetNo)}
                  fullWidth
                  helperText={touched.streetNo && errors.streetNo}
                  label="streetNo"
                  margin="normal"
                  name="streetNo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.streetNo}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  fullWidth
                  helperText={touched.postalCode && errors.postalCode}
                  label="postalCode"
                  margin="normal"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.postalCode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  label="City"
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(touched.country && errors.country)}
                  fullWidth
                  helperText={touched.country && errors.country}
                  label="country"
                  margin="normal"
                  name="country"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.country}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label="Description"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.link && errors.link)}
                  fullWidth
                  helperText={touched.link && errors.link}
                  label="Link"
                  margin="normal"
                  name="link"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.link}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours0 && errors.openingHours0)}
                  fullWidth
                  helperText={touched.openingHours0 && errors.openingHours0}
                  label="Sunday"
                  margin="normal"
                  name="openingHours0"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours0}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours1 && errors.openingHours1)}
                  fullWidth
                  helperText={touched.openingHours1 && errors.openingHours1}
                  label="Monday"
                  margin="normal"
                  name="openingHours1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours1}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours2 && errors.openingHours2)}
                  fullWidth
                  helperText={touched.openingHours2 && errors.openingHours2}
                  label="Tuesday"
                  margin="normal"
                  name="openingHours2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours2}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours3 && errors.openingHours3)}
                  fullWidth
                  helperText={touched.openingHours3 && errors.openingHours3}
                  label="Wednesday"
                  margin="normal"
                  name="openingHours3"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours3}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours4 && errors.openingHours4)}
                  fullWidth
                  helperText={touched.openingHours4 && errors.openingHours4}
                  label="Thursday"
                  margin="normal"
                  name="openingHours4"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours4}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours5 && errors.openingHours5)}
                  fullWidth
                  helperText={touched.openingHours5 && errors.openingHours5}
                  label="Friday"
                  margin="normal"
                  name="openingHours5"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours5}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  error={Boolean(touched.openingHours6 && errors.openingHours6)}
                  fullWidth
                  helperText={touched.openingHours6 && errors.openingHours6}
                  label="Saturday"
                  margin="normal"
                  name="openingHours6"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.openingHours6}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Field
                name="autocomplete"
                multiple
                component={Autocomplete}
                options={medicalFields.filter((myfield) => myfield.key !== 'all')}
                getOptionLabel={(option) => t(option.key) ?? option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={touched.autocomplete && !!errors.autocomplete}
                    helperText={touched.autocomplete && errors.autocomplete}
                    label="Medical fields"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Box my={2}>
              {isSubmitting && <LinearProgress />}
              <Grid item container justify="center" alignItems="center">
                <Button
                  className={classes.button}
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="outlined"
                >
                  Save
                </Button>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export { EditDoctorDrawer as default, handleFormErrorResponse };
