import React from 'react';
import {
  makeStyles
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import medicalFields from 'src/MedicalFields';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    color: theme.palette.background.navigation,
  },
  card: {
    borderRadius: 5,
  },
  indicator: {
    backgroundColor: theme.palette.background.navigation,
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

// eslint-disable-next-line react/prop-types
const Toolbar = ({ setSelectedMedicalField }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const sortedMedicalFields = medicalFields.filter((field) => field.key !== 'all');

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setSelectedMedicalField(sortedMedicalFields[newValue].key);
  };

  sortedMedicalFields.sort((a, b) => t(a.key) < t(b.key));
  sortedMedicalFields.push({ key: 'all' });
  sortedMedicalFields.reverse();
  return (
    <div>
      <Paper className={classes.card} variant="outlined">
        <Tabs
          className={classes.root}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs filering maps visablity"
          classes={{
            indicator: classes.indicator
          }}
        >
          {sortedMedicalFields.map((field, index) => (
            <Tab id={field.key} key={field.key} label={t(field.key)} {...a11yProps(field.key)} />
          ))}
        </Tabs>

      </Paper>
    </div>
  );
};

export default Toolbar;
