import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid, IconButton } from '@material-ui/core';
import { Call, Language, LocationOn } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    width: '100%',
    minHeight: '100%',
  },
  media: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    height: 100
  },
  content: {
    height: '100%'
  }
}));

const TourismMobileCard = ({ tourism, image }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card key={tourism.name + tourism.address} className={classes.root}>
      <CardContent className={classes.content}>
        <CardMedia
          className={classes.media}
          image={`/static/tourism/${tourism.name}.${image}`}
          component="img"
          alt={`static/tourism/${tourism.name}.${image}`}
        />
        <CardContent>
          <Typography variant="body1" color="textPrimary">
            {tourism.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {tourism.description}
          </Typography>
        </CardContent>
      </CardContent>
      <Divider variant="middle" />
      <CardActions>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid
            item
          >
            <IconButton className={classes.statsIcon} color="primary" href={`http://maps.google.com/?q=${tourism.address}`} aria-label="delete" size="small">
              <LocationOn fontSize="small" color="primary" />
            </IconButton>
          </Grid>
          <Grid
            item
          >
            <IconButton href={`tel:${tourism.phone}`} aria-label="delete" className={classes.iconButton} size="small">
              <Call fontSize="small" color="primary" />
            </IconButton>
          </Grid>
          <Grid
            item
          >
            <IconButton href={tourism.link} aria-label="delete" className={classes.iconButton} size="small">
              <Language fontSize="small" color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
export default TourismMobileCard;
