import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Card,
} from '@material-ui/core';
import {
  Storefront,
  Restaurant,
  House,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import NavItem from 'src/NavItem';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 160
  },
  desktopDrawer: {
    width: 160,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  card: {
    position: 'fixed',
    borderRadius: 5,
    paddingRight: theme.spacing(5),
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const items = [
    {
      href: '/tourism/restaurants',
      icon: Restaurant,
      title: t('restaurants')
    },
    {
      href: '/tourism/shops',
      icon: Storefront,
      title: t('shops')
    },
    {
      href: '/tourism/accomodation',
      icon: House,
      title: t('accomodation')
    },
  ];

  const content = (
    <List>
      {items.map((item) => (
        <NavItem
          href={item.href}
          key={item.title}
          title={item.title}
          icon={item.icon}
        />
      ))}
    </List>
  );

  return (
    <>
      <Hidden lgUp>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Divider />
          <Box p={0}>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              {content}
            </Drawer>

          </Box>
        </Box>
      </Hidden>
      <Hidden mdDown>
        <Card className={classes.card} variant="outlined">
          {content}
        </Card>
      </Hidden>
    </>
  );
};

export default NavBar;
