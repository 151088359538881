import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid
} from '@material-ui/core';

import { useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { useSnackbar } from 'notistack';
import { apiGetDoctor, apiGetDoctorPosts, toJson } from 'src/api';
import { useTranslation } from 'react-i18next';
import Profile from './Profile/Profile';
import Contact from './Profile/Contact';
import PracticeHours from './Profile/PracticeHours';
import Post from './Profile/Post';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh'
  },
  spacing: {
    marginBottom: theme.spacing(1),
  },
  appBar: {
    backgroundColor: theme.palette.background.navigation,
    color: 'white',
  },
  grids: {
    margin: theme.spacing(2),
  }
}));

const DoctorView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [doctor, setDoctor] = useState();
  const [posts = [], setPosts] = useState();
  const { t, i18n } = useTranslation();

  const noPostsPost = {
    content: {
      [i18n.language]: {
        title: t('errors.noPosts'),
        text: ''
      }
    },
    created_date: '2021-04-09T09:01:23.826297',
    id: 4
  };

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    apiGetDoctor(id)
      .then(toJson)
      .then(setDoctor)
      .catch(() => enqueueSnackbar(t('errors.noDoctor'), { variant: 'error' }));
  }, []);
  useEffect(() => {
    apiGetDoctorPosts(id)
      .then(toJson)
      .then(setPosts)
      .catch(() => setPosts([noPostsPost]));
  }, []);

  return (
    <Page className={classes.root} title="Profile">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        className={classes.profile}
      >
        <Profile doctor={doctor} />
      </Grid>
      <Grid
        container
        direction="row"
      >
        <Grid item xs={false} sm={false} md={1} lg={2} xl={3} />
        <Grid item direction="column" container xs={12} sm={6} md={4} lg={3} xl={2}>
          <Grid item className={classes.grids}>
            <PracticeHours doctor={doctor} />
          </Grid>
          <Grid item className={classes.grids}>
            <Contact doctor={doctor} />
          </Grid>
        </Grid>
        <Grid item direction="column" container xs={12} sm={6} md={6} lg={5} xl={4}>

          {posts?.map((item) => (
            <Grid key={item.id} className={classes.grids} item>
              <Post doctor={doctor} postInput={item} />
            </Grid>
          ))}

        </Grid>
      </Grid>
    </Page>
  );
};

export default DoctorView;
