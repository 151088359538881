import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Typography,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PartnersDashboard from './PartnersDhasboard';

const useStyles = makeStyles((theme) => ({
  div: {
    minHeight: '80%'
  },
  icon: {
    color: 'green',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontSize: theme.spacing(12),
  },
  mid: {
    marginTop: theme.spacing(4),
  }
}));

const Feedback = ({ hidden }) => {
  const classes = useStyles();
  const { t, i18next } = useTranslation();

  return (
    <div className={classes.div} hidden={hidden}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <CheckCircleOutlineIcon className={classes.icon} />
        </Grid>

        <Grid item xs={10}>
          <Typography align="center" variant="h6" gutterBottom>
            {t('feedback')}
          </Typography>
        </Grid>

        {/* <Grid item className={classes.mid}>
          <Typography color="primary" align="left" variant="h6" gutterBottom>
            {t('visit')}
          </Typography>
        </Grid>
        <Grid item container>
          <PartnersDashboard />
        </Grid> */}

      </Grid>
    </div>
  );
};
export default Feedback;
