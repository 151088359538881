import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { QueryClientProvider, QueryClient } from 'react-query';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import LoadingScreen from 'src/views/SuspenseLoading';
import { msalConfig } from 'src/authConfig';

import 'src/i18n';
import App from 'src/App';

const queryClient = new QueryClient();
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render((
  <MsalProvider instance={msalInstance}>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LoadingScreen />}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  </MsalProvider>
), document.getElementById('root'));
