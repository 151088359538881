import React from 'react';
import {
  Grid,
  Fab,
  makeStyles, Hidden,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Page from 'src/components/Page';
import Profile from './Profile';
import PracticeHours from './PracticeHours';
import OurPractice from './OurPractice';
import Contact from './Contact';
import Images from './Images';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  productCard: {
    height: '100%',
  },
  card: {
    borderRadius: 20,
  },
  profile: {
    paddingBottom: theme.spacing(2)
  },
  back: {
    position: 'fixed',
    top: 100,
    left: 10,
    opacity: 0.4

  },
  grids: {
    marginBottom: theme.spacing(2),
  }
}));
const posts = [
  {
    header: 'Versorgung unserer Patient*innen',
    body: 'Das Klinikum rechts der Isar verschiebt seit 11.12.20 planbare ambulante und stationäre Eingriffe, um die Kapazitäten für die Versorgung von Patient*innen mit dringenden Behandlungen und von Covid-19-Patient*innen zu bündeln. Weitere Informationen finden Sie hier. Die Versorgung von Patient*innen mit schwerwiegenden Erkrankungen (inkl. Krebs) ist gewährleistet. Bitte kommen Sie bei dringenden und lebensbedrohlichen Fällen weiterhin in das Klinikum rechts der Isar. Bitte beachten Sie die Maskenpflicht im gesamten Klinikum.',
  },
  {
    header: 'Besuchsregelung',
    body: 'Am Klinikum rechts der Isar sind – ähnlich wie am LMU-Klinikum – Besuche nur noch in begründeten Ausnahmefällen möglich. Dazu zählen u.a.:\n'
      + '\n'
      + 'Besuche von dementen und palliativen Patient*innen und Menschen, die im Sterben liegen\n'
      + 'Begleitung von Schwangeren während der Geburt\n'
      + 'Besuch einer Bezugsperson nach der Entbindung\n'
      + 'Besuche von Vater/Mutter/Erziehungsberechtigte*r von minderjährigen kranken Kindern\n'
      + 'In dringenden medizinischen Gründen (Vorabklärung mit Stationsarzt/-ärztin erforderlich)\n'
      + 'Eine vorherige Anmeldung über die entsprechende Station ist erforderlich. Wenn Besucher*innen in den letzten 14 Tagen auf Covid-19 getestet wurden, bitten wir Sie, die Bestätigung des negativen Testergebnisses mitzubringen.',
  },
  {
    header: 'Corona-Impfstudie',
    body: 'Am Universitätsklinikum rechts der Isar (MRI) läuft eine Corona-Impfstudie. In der internationalen klinischen Studie der Phase 3 wird die zweimalige Impfung mit einem Vektorimpfstoff der Janssen Pharmaceutical Companies of Johnson&Johnson geprüft. Wir sind das Leitende Prüfzentrum in Deutschland. Weitere Informationen finden Sie auf auf der Studien-Webseite: https://www.med2.mri.tum.de/de/forschung/klinische-forschung/covid-19-st...\n'
      + '\n',
  },
  {
    header: 'Patients from abroad\n',
    body: 'The German Federal Ministry of the Interior, Building and Community has gathered frequently asked questions and answers about the Coronavirus: Coronavirus-FAQ\n'
      + '\n',
  },
  {
    header: 'Geburten',
    body: 'Liebe Schwangere, Ihr Partner oder eine andere Begleitperson darf bei der Geburt und für die ersten zwei bis vier Stunden nach der Geburt Ihres Kindes im Kreißsaal bei Ihnen sein. Väter dürfen täglich für eine Stunde auf die Wochenbettstation zu Besuch kommen. Die zeitliche Abstimmung der Besuche erfolgt über die Station. Unsere ausführlichen Infos zum derzeitigen Ablauf von Geburtsanmeldung und Geburt finden Sie hier.',
  },
];

const ProfileView = ({ doctorProfile, hiddenProfile, setHiddenProfile }) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Profile"
    >
      <Fab size="small" onClick={() => (setHiddenProfile(!hiddenProfile))} color="secondary" className={classes.back}>
        <KeyboardBackspaceIcon fontSize="small" />
      </Fab>
      <Hidden smDown>

        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          className={classes.profile}
        >
          <Profile doctor={doctorProfile} />
          <Images />
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={3}
        >
          <Grid item xs={false} sm={false} md={1} lg={2} xl={3} />
          <Grid item direction="column" container xs={12} sm={6} md={4} lg={3} xl={2} spacing={2}>
            <Grid item>
              <PracticeHours doctor={doctorProfile} />
            </Grid>
            <Grid item>
              <Contact doctor={doctorProfile} />
            </Grid>
          </Grid>
          <Grid item direction="column" container xs={12} sm={6} md={6} lg={5} xl={4} spacing={2}>

            {posts.map((post) => (
              <Grid key={post.header} item>
                <OurPractice doctor={doctorProfile} post={post} />
              </Grid>
            ))}

          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid className={classes.grids} item xs={12} sm={12}>
            <Profile doctor={doctorProfile} />
          </Grid>
          <Grid className={classes.grids} item xs={12} sm={12}>
            <Images />
          </Grid>
          {posts.map((post) => (
            <Grid item key={post.header} className={classes.grids} xs={12} sm={12}>
              <OurPractice doctor={doctorProfile} post={post} />
            </Grid>
          ))}
          <Grid className={classes.grids} item>
            <PracticeHours doctor={doctorProfile} />
          </Grid>
          <Grid className={classes.grids} item>
            <Contact doctor={doctorProfile} />
          </Grid>
        </Grid>
      </Hidden>
    </Page>
  );
};

export default ProfileView;
